export interface J2KDecoder {
  worker: Worker;
  j2kdecode: (src: any) => Promise<any>;
}

export class J2KDecoder {
  constructor() {
    this.worker = new Worker(new URL("./workers/j2k-worker", import.meta.url));

    this.j2kdecode = (bytes: any): any =>
      new Promise((resolve, _reject) => {
        this.worker.onmessage = (evt: MessageEvent<Uint8Array>) => {
          resolve(evt.data);
        };

        this.worker.postMessage(bytes);
      });
  }
}
