import React from "react";
import styled from "styled-components";

const RootDiv = styled.div`
  display: flex;
  padding: 0px 10px 10px 10px;
  height: 35px;
`;

const IconDiv = styled.div`
  padding: 5px 8px 8px 0px;
  color: #016060;
  text-align: center;
`;

const InputBox = styled.input.attrs((props) => ({
  ref: props.ref,
  type: props.type,
}))`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 5px;
`;

const Input = ({
  inputRef,
  icon,
  password,
  placeholder,
}: {
  inputRef: any;
  icon: any;
  password?: boolean;
  placeholder: string;
}) => {
  const type = password ? "password" : "text";

  return (
    <RootDiv>
      <IconDiv>{icon}</IconDiv>
      <InputBox ref={inputRef} type={type} placeholder={placeholder} />
    </RootDiv>
  );
};

export default Input;
