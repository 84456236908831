import React from "react";
import styled, { css } from "styled-components";

import { AppContext } from "../../context";

const RootDiv = styled.div`
  height: 20px;
  background-color: #016060;
  border-top: 5px solid black;
  padding-left: 5px;
  color: white;
  user-select: none;
  font-size: 12px;

  ${({ print }: { print: boolean }) =>
    print &&
    css`
      display: none;
    `}
`;

const TextSpan = styled.span`
  display: table-cell;
  vertical-align: middle;
  height: 20px;
`;

const Footer = () => {
  const app = React.useContext(AppContext);

  return (
    <RootDiv print={app.print.enabled}>
      <TextSpan></TextSpan>
    </RootDiv>
  );
};

export default Footer;
