import React from "react";
import { RiCheckboxBlankLine, RiCheckboxLine } from "react-icons/ri";
import styled from "styled-components";

const IconDiv = styled.div`
  float: left;
  color: white;
  padding-right: 5px;
`;

const TextSpan = styled.span`
  color: white;
`;

const ShortcutSpan = styled.span`
  float: right;
  color: white;
`;

const ButtonDiv = styled.div`
  padding: 3px;
  border-radius: 5px;

  :hover {
    background-color: #218080;
    transition: background-color 0.5s ease;
  }
`;

const Icon = ({ checked }: { checked: boolean }) => {
  return (
    <IconDiv>
      {checked ? (
        <RiCheckboxLine size={18} />
      ) : (
        <RiCheckboxBlankLine size={18} />
      )}
    </IconDiv>
  );
};

export const TextCheckButton = ({
  label,
  shortcut,
  checked,
  onClick,
  onMouseDown,
}: {
  label: string;
  shortcut: string;
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <ButtonDiv onClick={onClick} onMouseDown={onMouseDown}>
      {checked !== undefined && <Icon checked={checked} />}
      <TextSpan>{label}</TextSpan>
      <ShortcutSpan>{shortcut}</ShortcutSpan>
    </ButtonDiv>
  );
};

export const SpacerDiv = styled.div`
  height: 1px;
  background: white;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.7;
`;
