import React from "react";
import { RiUserFill } from "react-icons/ri";
import {
  TbLetterA,
  TbLetterB,
  TbLetterC,
  TbLetterD,
  TbLetterE,
  TbLetterF,
  TbLetterG,
  TbLetterH,
  TbLetterI,
  TbLetterJ,
  TbLetterK,
  TbLetterL,
  TbLetterM,
  TbLetterN,
  TbLetterO,
  TbLetterP,
  TbLetterQ,
  TbLetterR,
  TbLetterS,
  TbLetterT,
  TbLetterU,
  TbLetterV,
  TbLetterW,
  TbLetterX,
  TbLetterY,
  TbLetterZ,
} from "react-icons/tb";

export const UserIcon = ({ user, size }: { user?: string, size: number }) => {
  let value = undefined;

  if (user) {
    switch (user[0].toLocaleLowerCase()) {
      case "a":
        value = <TbLetterA size={size} />;
        break;
      case "b":
        value = <TbLetterB size={size} />;
        break;
      case "c":
        value = <TbLetterC size={size} />;
        break;
      case "d":
        value = <TbLetterD size={size} />;
        break;
      case "e":
        value = <TbLetterE size={size} />;
        break;
      case "f":
        value = <TbLetterF size={size} />;
        break;
      case "g":
        value = <TbLetterG size={size} />;
        break;
      case "h":
        value = <TbLetterH size={size} />;
        break;
      case "i":
        value = <TbLetterI size={size} />;
        break;
      case "j":
        value = <TbLetterJ size={size} />;
        break;
      case "k":
        value = <TbLetterK size={size} />;
        break;
      case "l":
        value = <TbLetterL size={size} />;
        break;
      case "m":
        value = <TbLetterM size={size} />;
        break;
      case "n":
        value = <TbLetterN size={size} />;
        break;
      case "o":
        value = <TbLetterO size={size} />;
        break;
      case "p":
        value = <TbLetterP size={size} />;
        break;
      case "q":
        value = <TbLetterQ size={size} />;
        break;
      case "r":
        value = <TbLetterR size={size} />;
        break;
      case "s":
        value = <TbLetterS size={size} />;
        break;
      case "t":
        value = <TbLetterT size={size} />;
        break;
      case "u":
        value = <TbLetterU size={size} />;
        break;
      case "v":
        value = <TbLetterV size={size} />;
        break;
      case "w":
        value = <TbLetterW size={size} />;
        break;
      case "x":
        value = <TbLetterX size={size} />;
        break;
      case "y":
        value = <TbLetterY size={size} />;
        break;
      case "z":
        value = <TbLetterZ size={size} />;
        break;
      default:
        value = <RiUserFill size={size} />;
    }
  } else {
    value = <RiUserFill size={size} />;
  }

  return <React.Fragment>{value}</React.Fragment>;
};
