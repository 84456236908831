import { readWadoString } from "./dicom";
import { GridView } from "./global";

export interface DicomTag {
  id: string;
  value: string;
}

export interface ProtocolViewport {
  dicomTags: DicomTag[];
}

export interface Protocol {
  modality: string;
  gridView: GridView;
  viewports: ProtocolViewport[];
}

export class Protocol {
  constructor(
    modality: string,
    gridView: GridView,
    viewports: ProtocolViewport[]
  ) {
    this.modality = modality;
    this.gridView = gridView;
    this.viewports = viewports;
  }

  viewport(metadata: any): number | undefined {
    for (let i = 0; i < this.viewports.length; i++) {
      let matched = true;

      for (let k = 0; k < this.viewports[i].dicomTags.length; k++) {
        const dicomTag = this.viewports[i].dicomTags[k];
      
        if (typeof metadata[dicomTag.id] !== "undefined") {
          let raw = readWadoString(metadata[dicomTag.id]);
          if (raw !== dicomTag.value) {
            matched = false;
            break;
          }
        }
      }

      if (matched) return i;
    }

    return undefined;
  }
}

export interface HangingProtocols {
  protocols: Protocol[];
}

const mamography = new Protocol("MG", new GridView(1, 2), [
  {
    dicomTags: [
      {
        id: "0008103E",
        value: "L MLO",
      },
    ],
  },
]);

export class HangingProtocols {
  constructor() {
    this.protocols = [mamography];
  }

  protocol(modsInStudy: string[]): Protocol | undefined {
    for (let i = 0; i < this.protocols.length; i++) {
      if (modsInStudy.includes(this.protocols[i].modality)) {
        return this.protocols[i];
      }
    }
  }
}

export const defHangingProtocols = new HangingProtocols();

export function hangingProtocolsFromUser(
  src: HangingProtocols
): HangingProtocols {
  return src;
}
