import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";

import TopBar from "../components/view/topbar";
import NavBar from "../components/view/navbar";
import Screen from "../components/view/screen";
import Footer from "../components/view/footer";

import AlertSnackbar from "../components/view/alertSnackbar";
import AuthModal from "../components/view/authModal";

import {
  AppProvider,
  AlertProvider,
  WasmProvider,
} from "../context";

const View = () => {
  return (
    <AppProvider>
      <AlertProvider>
        <WasmProvider>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div>
              <TopBar />
              <NavBar />
              <Screen isMobile={isMobile} />
              <Footer />
              <AlertSnackbar />
              <AuthModal />
            </div>
          </DndProvider>
        </WasmProvider>
      </AlertProvider>
    </AppProvider>
  );
};

export default View;
