import React from "react";
import styled from "styled-components";

import Viewport from "./viewport";
import { AppContext } from "../../context";
import { calcPrintSize } from "../../global";

const RootDiv = styled.div.attrs(
  ({ show, print }: { show: boolean; print: boolean }): any => ({
    style: {
      marginLeft: show && !print ? "170px" : "0px",
      paddingLeft: print ? "0px" : "5px",
      paddingRight: print ? "0px" : "5px",
      height: print ? "auto" : "calc(100vh - 52px - 25px)",
    },
  })
)`
  margin: 0px 0px 0px 0px;
  background-color: black;
  user-select: none;
`;

const GridDiv = styled.div.attrs(
  ({
    rows,
    cols,
    print,
  }: {
    rows: number;
    cols: number;
    print: boolean;
  }): any => ({
    style: {
      gridTemplateRows: "repeat(" + rows + ", auto)",
      gridTemplateColumns: "repeat(" + cols + ", auto)",
      height: print ? "297mm" : "calc(100vh - 57px - 20px)",
      width: print ? "210mm" : "auto",
    },
  })
)`
  background-color: #011010;
  display: grid;
  grid-gap: 5px;
  justify-items: stretch;
  align-items: stretch;
`;

const Screen = ({ isMobile }: { isMobile: boolean }) => {
  const app = React.useContext(AppContext);
  const count = app.gridView.rows * app.gridView.cols;

  const [loadedCount, setLoadedCount] = React.useState(1);
  const [printWidth, setPrintWidth] = React.useState("210mm");
  const [printHeight, setPrintHeight] = React.useState("297mm");

  React.useEffect(() => {
    const { width, height } = calcPrintSize(app.print.size, app.print.layout);
    setPrintWidth(width);
    setPrintHeight(height);
  }, [app.print.size, app.print.layout]);

  React.useEffect(() => {
    if (loadedCount < count) setLoadedCount(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <RootDiv show={app.studies.length > 0} print={app.print.enabled}>
      <GridDiv
        rows={app.gridView.rows}
        cols={app.gridView.cols}
        print={app.print.enabled}
        printWidth={printWidth}
        printHeight={printHeight}
      >
        <Viewport key={1} index={1} />
        {loadedCount > 1 && (
          <div style={{ display: count > 1 ? "contents" : "none" }}>
            <Viewport key={2} index={2} />
          </div>
        )}
        {loadedCount > 2 && (
          <div style={{ display: count > 2 ? "contents" : "none" }}>
            <Viewport key={3} index={3} />
          </div>
        )}
        {loadedCount > 3 && (
          <div style={{ display: count > 3 ? "contents" : "none" }}>
            <Viewport key={4} index={4} />
          </div>
        )}
        {loadedCount > 4 && (
          <div style={{ display: count > 4 ? "contents" : "none" }}>
            <Viewport key={5} index={5} />
          </div>
        )}
        {loadedCount > 5 && (
          <div style={{ display: count > 5 ? "contents" : "none" }}>
            <Viewport key={6} index={6} />
          </div>
        )}
        {loadedCount > 6 && (
          <div style={{ display: count > 6 ? "contents" : "none" }}>
            <Viewport key={7} index={7} />
          </div>
        )}
        {loadedCount > 7 && (
          <div style={{ display: count > 7 ? "contents" : "none" }}>
            <Viewport key={8} index={8} />
          </div>
        )}
        {loadedCount > 8 && (
          <div style={{ display: count > 8 ? "contents" : "none" }}>
            <Viewport key={9} index={9} />
          </div>
        )}
        {loadedCount > 9 && (
          <div style={{ display: count > 9 ? "contents" : "none" }}>
            <Viewport key={10} index={10} />
          </div>
        )}
        {loadedCount > 10 && (
          <div style={{ display: count > 10 ? "contents" : "none" }}>
            <Viewport key={11} index={11} />
          </div>
        )}
        {loadedCount > 11 && (
          <div style={{ display: count > 11 ? "contents" : "none" }}>
            <Viewport key={12} index={12} />
          </div>
        )}
        {loadedCount > 12 && (
          <div style={{ display: count > 12 ? "contents" : "none" }}>
            <Viewport key={13} index={13} />
          </div>
        )}
        {loadedCount > 13 && (
          <div style={{ display: count > 13 ? "contents" : "none" }}>
            <Viewport key={14} index={14} />
          </div>
        )}
        {loadedCount > 14 && (
          <div style={{ display: count > 14 ? "contents" : "none" }}>
            <Viewport key={15} index={15} />
          </div>
        )}
        {loadedCount > 15 && (
          <div style={{ display: count > 15 ? "contents" : "none" }}>
            <Viewport key={16} index={16} />
          </div>
        )}
      </GridDiv>
    </RootDiv>
  );
};

export default Screen;
