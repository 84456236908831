import axios from "axios";
import { chainTokenHeader, Iuids } from "./global";
import { HangingProtocols, Protocol } from "./hangingProtocols";

enum VR {
  DA = "DA",
  PN = "PN",
  TM = "TM",
  LO = "LO",
  IS = "IS",
  CS = "CS",
  UI = "UI",
}

interface WadoName {
  Alphabetic: string | undefined;
  Ideographic: string | undefined;
  Phonetic: string | undefined;
}

const getNameValue = (src: WadoName[]): string => {
  if (typeof src[0] === "undefined") {
    return "";
  } else if (typeof src[0].Alphabetic !== "undefined") {
    return src[0].Alphabetic;
  } else if (typeof src[0].Ideographic !== "undefined") {
    return src[0].Ideographic;
  } else if (typeof src[0].Phonetic !== "undefined") {
    return src[0].Phonetic;
  } else {
    return "";
  }
};

const getStringValue = (src: string[]): string => {
  if (typeof src[0] === "undefined") {
    throw new Error("Malformed Value field!");
  } else {
    return src[0];
  }
};

const getNumberValue = (src: number[]): number => {
  if (typeof src[0] === "undefined") {
    throw new Error("Malformed Value field!");
  } else {
    return src[0];
  }
};

export interface WadoItem {
  Value: WadoName[] | string[] | number[];
  vr: VR;
}

export const readWadoString = (src: WadoItem): string => {
  switch (src.vr) {
    case VR.PN:
      return getNameValue(src.Value as WadoName[]);
    case VR.DA:
    case VR.TM:
    case VR.LO:
    case VR.CS:
    case VR.UI:
      return getStringValue(src.Value as string[]);
    default:
      throw new Error(src.vr + " can't return string value!");
  }
};

const readWadoNumber = (src: WadoItem): number => {
  switch (src.vr) {
    case VR.IS:
      return getNumberValue(src.Value as number[]);
    default:
      throw new Error(src.vr + " can't return number value!");
  }
};

export interface Series {
  seriesIuid: string;
  seriesDesc: string;
  seriesInstCount: number;
  seriesNumber: number;
  modality: string;
  wado: any;
}

export interface Study {
  studyIuid: string;
  patName: string;
  patBirthDate: string;
  studyDate: string;
  studyTime: string;
  studyDesc: string;
  modsInStudy: string[];
  series: Series[];
  pacsPath: string;
  protocol?: Protocol;
}

export const getStudy = (
  path: string,
  studyIuid: string,
  hangingProtocols: HangingProtocols
): Promise<Study> => {
  const reqStudy = axios.get(
    path + "/studies?0020000D=" + studyIuid + "&includefield=00081030",
    { headers: chainTokenHeader({}) }
  );

  const reqSeries = axios.get(path + "/series?0020000D=" + studyIuid, {
    headers: chainTokenHeader({}),
  });

  const parseStudy = (src: any[]): Study => {
    let study: Study = {
      studyIuid: "",
      patName: "",
      patBirthDate: "",
      studyDate: "",
      studyTime: "",
      studyDesc: "",
      modsInStudy: [],
      series: [],
      pacsPath: "",
    };

    if (typeof src[0]["0020000D"] !== "undefined")
      study.studyIuid = readWadoString(src[0]["0020000D"]);

    if (typeof src[0]["00100010"] !== "undefined")
      study.patName = readWadoString(src[0]["00100010"]);

    if (typeof src[0]["00100030"] !== "undefined")
      study.patBirthDate = readWadoString(src[0]["00100030"]);

    if (typeof src[0]["00080020"] !== "undefined")
      study.studyDate = readWadoString(src[0]["00080020"]);

    if (typeof src[0]["00080030"] !== "undefined")
      study.studyTime = readWadoString(src[0]["00080030"]);

    if (typeof src[0]["00081030"] !== "undefined")
      study.studyDesc = readWadoString(src[0]["00081030"]);

    return study;
  };

  const parseSeries = (src: any): Series => {
    let series: Series = {
      seriesIuid: "",
      seriesDesc: "",
      seriesInstCount: 0,
      seriesNumber: 0,
      modality: "",
      wado: src,
    };

    if (typeof src["0020000E"] !== "undefined")
      series.seriesIuid = readWadoString(src["0020000E"]);

    if (typeof src["0008103E"] !== "undefined")
      series.seriesDesc = readWadoString(src["0008103E"]);

    if (typeof src["00201209"] !== "undefined")
      series.seriesInstCount = readWadoNumber(src["00201209"]);

    if (typeof src["00200011"] !== "undefined")
      series.seriesNumber = readWadoNumber(src["00200011"]);

    if (typeof src["00080060"] !== "undefined") {
      series.modality = readWadoString(src["00080060"]);
    }

    return series;
  };

  return new Promise((resolve, reject) => {
    axios
      .all([reqStudy, reqSeries])
      .then(
        axios.spread(({ data: srcStudy }, { data: srcSeries }) => {
          if (srcStudy.length !== 1)
            throw Error("Invalid studies QIDO response.");
          if (srcSeries.length === 0)
            throw Error("Invalid series QIDO response.");

          let study = parseStudy(srcStudy);

          for (let i = 0; i < srcSeries.length; i++) {
            let series = parseSeries(srcSeries[i]);
            study.series.push(series);

            const index = study.modsInStudy.findIndex(
              (object) => object === series.modality
            );

            if (index === -1) study.modsInStudy.push(series.modality);
          }

          study.protocol = hangingProtocols.protocol(study.modsInStudy);
          resolve(study);
        })
      )
      .catch((errors) => {
        reject(errors);
      });
  });
};

export class Viewport {
  width = 0;
  height = 0;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

export const getRendered = (
  path: string,
  iuids: Iuids,
  viewport?: Viewport
): Promise<Blob> => {
  let rootUrl =
    path +
    "/studies/" +
    iuids.study +
    "/series/" +
    iuids.series +
    "/instances/" +
    iuids.sop +
    "/rendered";

  if (viewport) {
    rootUrl += "?viewport=" + viewport.width + "," + viewport.height;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(rootUrl, {
        headers: chainTokenHeader({
          Accept: "image/jpeg",
        }),
        responseType: "blob",
      })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* 
This query return an array of all instances with the same Series Instance UID.  

raw: Contains the original response from the WADO server.
parsed: Contains only important values for navbar component.
*/

export interface SeriesMetadataParsed {
  sopClassUid?: string;
  sopIuid?: string;
  numberOfFrames: number;
}

export interface SeriesMetadata {
  raw: Object;
  parsed: SeriesMetadataParsed;
}

export const getSeriesMetadata = (
  path: string,
  iuids: Iuids
): Promise<SeriesMetadata[]> => {
  let rootUrl =
    path + "/studies/" + iuids.study + "/series/" + iuids.series + "/metadata";

  return new Promise((resolve, reject) => {
    axios
      .get(rootUrl, {
        headers: chainTokenHeader({}),
      })
      .then(({ data }) => {
        let out: SeriesMetadata[] = [];

        data.forEach((el: any) => {
          let sopClassUid;
          let sopIuid;
          let numberOfFrames;

          if (typeof el["00080016"] !== "undefined") {
            sopClassUid = readWadoString(el["00080016"]);
          }

          if (typeof el["00080018"] !== "undefined") {
            sopIuid = readWadoString(el["00080018"]);
          }

          if (typeof el["00280008"] !== "undefined") {
            numberOfFrames = readWadoNumber(el["00280008"]);
          }

          out.push({
            raw: el,
            parsed: {
              sopClassUid,
              sopIuid,
              numberOfFrames: numberOfFrames || 0,
            },
          });
        });

        resolve(out);
      })
      .catch((error) => reject(error));
  });
};
