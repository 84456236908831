import React from "react";
import styled from "styled-components";
import { DragPreviewImage, useDrag } from "react-dnd";

import { FaFilePdf, FaEyeSlash } from "react-icons/fa";

import { Iuids } from "../../../global";
import { getRendered, Viewport } from "../../../dicom";

import Spinner from "../spinner";
import { BoxDiv, MediumTextDiv } from ".";

enum BoxIconType {
  Loading,
  Invalid,
  PDF,
}

export interface BoxItem {
  studyIuid: string;
  seriesIuid: string;
  sopIuid: string;
  frames: number;
  description: string;
  seriesNo: number;
  pdf: boolean;
  group: boolean;
  sops: string[];
  wadoInstances: Object[];
  pacsPath: string;
}

const ImageBoxDiv = styled.div`
  width: 120px;
  height: 120px;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagesNumberSpan = styled.span`
  background-color: black;
  position: absolute;
  bottom: 0px;
  left: 2px;
  font-size: 12px;
`;

const ImagesCounterSpan = styled.span`
  background-color: black;
  position: absolute;
  bottom: 0px;
  right: 2px;
  font-size: 12px;
`;

const LoadingBoxDiv = styled.div.attrs((props: { progress: number }): any => ({
  style: {
    width: (props.progress * 120) / 100 + "px",
  },
}))`
  height: 10px;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.5;
`;

function IntoUIDS(src: BoxItem): Iuids {
  return {
    study: src.studyIuid,
    series: src.seriesIuid,
    sop: src.sopIuid,
  };
}

const LoadingBox = ({ item }: { item: BoxItem }) => {
  const [count, setCount] = React.useState<number>(0);

  const handleLoaded = React.useCallback(
    (evt: any) => {
      try {
        const value = JSON.parse(evt.detail);

        if (
          item.studyIuid === value.study &&
          item.seriesIuid === value.series
        ) {
          if (item.group) {
            if (item.sops.includes(value.sop)) setCount((el) => el + 1);
          } else {
            if (item.sopIuid === value.sop) setCount((el) => el + 1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [item]
  );

  React.useEffect(() => {
    window.addEventListener("loaded", handleLoaded);

    return () => {
      window.removeEventListener("loaded", handleLoaded);
    };
  }, [handleLoaded]);

  return <LoadingBoxDiv progress={(count * 100) / item.frames} />;
};

const Box = ({ item }: { item: BoxItem }) => {
  // preview image
  const [image, setImage] = React.useState<Blob | null>(null);
  // icon if image is null
  const [seriesIcon, setSeriesIcon] = React.useState<BoxIconType>(
    BoxIconType.Loading
  );

  const iuids = IntoUIDS(item);

  React.useEffect(() => {
    const viewport = new Viewport(120, 120);

    if (item.pdf) {
      setSeriesIcon(BoxIconType.PDF);
    } else {
      getRendered(item.pacsPath, iuids, viewport)
        .then((data: Blob) => setImage(data))
        .catch((error) => {
          console.log(error);
          setSeriesIcon(BoxIconType.Invalid);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "series",
    item: item.wadoInstances,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const BoxIcon = ({ type }: { type: BoxIconType }) => {
    switch (type) {
      case BoxIconType.Loading:
        return <Spinner />;
      case BoxIconType.PDF:
        return <FaFilePdf size={62} />;
      default:
        return <FaEyeSlash size={62} />;
    }
  };

  const imageSrc = image ? URL.createObjectURL(image) : "";

  return (
    <React.Fragment>
      <DragPreviewImage connect={preview} src={imageSrc} />
      <BoxDiv
        series
        ref={drag}
        selected={isDragging}
        onClick={() => {
          const event = new CustomEvent("setSeries", {
            detail: item.wadoInstances,
          });
          window.dispatchEvent(event);
        }}
      >
        <MediumTextDiv>
          <span>{item.description}</span>
        </MediumTextDiv>
        <ImageBoxDiv>
          <LoadingBox item={item} />
          {image === null ? (
            <BoxIcon type={seriesIcon} />
          ) : (
            <React.Fragment>
              <img src={imageSrc} alt={item.description} draggable="false" />
              {item.frames && (
                <React.Fragment>
                  <ImagesNumberSpan>{"#" + item.seriesNo}</ImagesNumberSpan>
                  <ImagesCounterSpan>{item.frames}</ImagesCounterSpan>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </ImageBoxDiv>
      </BoxDiv>
    </React.Fragment>
  );
};

export default React.memo(Box);
