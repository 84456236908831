import React from "react";
import styled, { css } from "styled-components";
import useEventListener from "@utilityjs/use-event-listener";
import { useDrop } from "react-dnd";
import Spinner from "./spinner";

import { WasmContext, AppContext } from "../../context";
import { Dimensions, WasmCanvas } from "../../global";
import { RiBookmarkFill } from "react-icons/ri";

const RootDiv = styled.div`
  position: relative;
  border-radius: 3px;

  ${(props: { isActive: boolean; selected: boolean }) => {
    if (props.isActive && !props.selected) {
      return css`
        background-color: #707171;
        border: 2px solid #707171;
      `;
    } else if (props.isActive && props.selected) {
      return css`
        background-color: #707171;
        border: 2px solid #016060;
      `;
    } else if (!props.isActive && props.selected) {
      return css`
        background-color: #404141;
        border: 2px solid #016060;
      `;
    } else {
      return css`
        background-color: #404141;
        border: 2px solid #404141;
      `;
    }
  }}
`;

const Canvas = styled.canvas`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 2px;
`;

const Annotations = styled.div`
  z-index: 1;
  color: white;
  text-shadow: 2px 0 black, -2px 0 black, 0 2px black, 0 -2px black,
    1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
  position: absolute;
  padding: 2px 5px;
  pointer-events: none;

  ${(props: { position?: number; fontAndSize: FontAndSize }) => {
    if (props.position === 1) {
      return css`
        top: 0;
        left: 0;
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 2) {
      return css`
        top: 0;
        right: 0;
        text-align: right;
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 3) {
      return css`
        bottom: 0;
        left: 0;
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 4) {
      return css`
        bottom: 0;
        right: 0;
        text-align: right;
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 5) {
      return css`
        bottom: ${props.fontAndSize.size}px;
        left: 0;
        text-align: left;
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 6) {
      return css`
        top: 0;
        left: 50%;
        text-align: center;
        transform: translate(-50%, 0%);
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 7) {
      return css`
        bottom: 0;
        left: 50%;
        text-align: center;
        transform: translate(-50%, 0%);
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 8) {
      return css`
        bottom: 50%;
        right: 0;
        text-align: right;
        font-size: ${props.fontAndSize.font}px;
      `;
    } else if (props.position === 9) {
      return css`
        bottom: 50%;
        left: 0;
        text-align: left;
        font-size: ${props.fontAndSize.font}px;
      `;
    }
  }};
`;

const BookmarkDiv = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: calc(25% - 25px);
  color: red;
  visibility: hidden;

  ${(props: { position?: number; fontAndSize: FontAndSize }) => {
    return css`
      font-size: ${props.fontAndSize.font}px;
    `;
  }}
`;

const SpinnerDiv = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
`;

interface FontAndSize {
  font: number;
  size: number;
}

const MIN_FONT_SIZE = 50;
const MED_FONT_SIZE = 200;
const MAX_FONT_SIZE = 360;

const calcFontSizeLabel = (dimensions: Dimensions | undefined): FontAndSize => {
  if (dimensions) {
    if (dimensions.width < MIN_FONT_SIZE || dimensions.height < MIN_FONT_SIZE) {
      return {
        font: 2,
        size: 5,
      };
    } else if (
      (dimensions.width >= MIN_FONT_SIZE && dimensions.width < MED_FONT_SIZE) ||
      (dimensions.height >= MIN_FONT_SIZE && dimensions.height < MED_FONT_SIZE)
    ) {
      return {
        font: 5,
        size: 12,
      };
    } else if (
      (dimensions.width >= MED_FONT_SIZE && dimensions.width < MAX_FONT_SIZE) ||
      (dimensions.height >= MED_FONT_SIZE && dimensions.height < MAX_FONT_SIZE)
    ) {
      return {
        font: 8,
        size: 19,
      };
    } else {
      return {
        font: 15,
        size: 37,
      };
    }
  } else {
    return {
      font: 15,
      size: 37,
    };
  }
};

const getSize = (ref: React.RefObject<HTMLCanvasElement>) => {
  if (ref.current) {
    return new Dimensions(ref.current.clientWidth, ref.current.clientHeight);
  } else {
    return undefined;
  }
};

const Viewport = ({ index }: { index: number }) => {
  const app = React.useContext(AppContext);
  const wasm = React.useContext(WasmContext);

  const elementId = index.toString();
  const selected = index === app.selected;

  const [canvas, setCanvas] = React.useState<WasmCanvas>();
  const [wadoInstances, setWadoInstances] = React.useState<Object[]>([]);
  const [dimensions, setDimensions] = React.useState<Dimensions | undefined>();
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const [fontAndSize, setFontAndSize] = React.useState<FontAndSize>({
    font: 15,
    size: 37,
  });

  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  /* Keyboard Shortcuts */
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (!app.blockShortcuts) {
        if (selected) {
          if (app.shortcuts.nextImage.test(event)) {
            canvas?.nextImage();
          }

          if (app.shortcuts.prevImage.test(event)) {
            canvas?.prevImage();
          }
        }

        if (event.ctrlKey) {
          canvas?.keyDown("ctrl");
        }
      }
    },
    [app, canvas, selected]
  );

  useEventListener({
    target: window,
    eventType: "keydown",
    handler: handleKeyDown,
  });

  const handleKeyUp = React.useCallback(
    (event: KeyboardEvent) => {
      if (!event.ctrlKey) {
        canvas?.keyUp("ctrl");
      }
    },
    [canvas]
  );

  useEventListener({
    target: window,
    eventType: "keyup",
    handler: handleKeyUp,
  });

  /* Global Event Listeners */
  const handleReset = React.useCallback((): void => {
    if (selected) canvas?.reset();
  }, [canvas, selected]);

  const handleResize = React.useCallback(
    (): void => setDimensions(getSize(canvasRef)),
    []
  );

  const handleInvert = React.useCallback((): void => {
    if (selected) canvas?.invert();
  }, [canvas, selected]);

  const handleFixedZoom = React.useCallback(
    (evt: any): void => {
      if (selected) canvas?.fixedZoom(evt.detail.zoom);
    },
    [canvas, selected]
  );

  const handleFixedWindow = React.useCallback(
    (evt: any): void => {
      if (selected) canvas?.setWindow(evt.detail.center, evt.detail.width);
    },
    [canvas, selected]
  );

  const handleSetDefaultWindow = React.useCallback((): void => {
    if (selected) canvas?.setDefaultWindow();
  }, [canvas, selected]);

  const handleSetFullDynamic = React.useCallback((): void => {
    if (selected) canvas?.setFullDynamic();
  }, [canvas, selected]);

  const handleFillViewport = React.useCallback((): void => {
    if (selected) canvas?.fillViewport();
  }, [canvas, selected]);

  const handleSetSeries = React.useCallback(
    (evt: any): void => {
      if (canvas && selected && evt.detail) {
        setWadoInstances(evt.detail);
      }
    },
    [canvas, selected]
  );

  const handlePlaySpeedUp = React.useCallback((): void => {
    if (selected) canvas?.playSpeedUp();
  }, [canvas, selected]);

  const handlePlaySpeedDown = React.useCallback((): void => {
    if (selected) canvas?.playSpeedDown();
  }, [canvas, selected]);

  const handlePlayStop = React.useCallback((): void => {
    if (selected) canvas?.playStop();
  }, [canvas, selected]);

  const handleBookmark = React.useCallback((): void => {
    if (selected) canvas?.bookmark();
  }, [canvas, selected]);

  const handleLoaded = React.useCallback((): void => {
    setSpinner(false);
  }, [setSpinner]);

  const handleSave = React.useCallback((): void => {
    const studyIuid = app.studies[0].studyIuid;

    wasm.global?._insert_bookmarks(studyIuid).then((resp) => {
      if (resp) wasm.global?._update_bookmarks(studyIuid);
    });
  }, [app.studies, wasm.global]);

  React.useEffect(() => {
    window.addEventListener("save", handleSave);

    return () => {
      window.removeEventListener("save", handleSave);
    };
  });

  React.useEffect(() => {
    window.addEventListener("reset", handleReset);
    window.addEventListener("resize", handleResize);
    window.addEventListener("invert", handleInvert);
    window.addEventListener("fixedZoom", handleFixedZoom);
    window.addEventListener("fixedWindow", handleFixedWindow);
    window.addEventListener("setDefaultWindow", handleSetDefaultWindow);
    window.addEventListener("setFullDynamic", handleSetFullDynamic);
    window.addEventListener("fillViewport", handleFillViewport);
    window.addEventListener("setSeries", handleSetSeries);
    window.addEventListener("playSpeedUp", handlePlaySpeedUp);
    window.addEventListener("playSpeedDown", handlePlaySpeedDown);
    window.addEventListener("playStop", handlePlayStop);
    window.addEventListener("bookmark", handleBookmark);

    window.addEventListener("loaded" + elementId, handleLoaded);

    return () => {
      window.removeEventListener("reset", handleReset);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("invert", handleInvert);
      window.removeEventListener("fixedZoom", handleFixedZoom);
      window.removeEventListener("fixedWindow", handleFixedWindow);
      window.removeEventListener("setDefaultWindow", handleSetDefaultWindow);
      window.removeEventListener("setFullDynamic", handleSetFullDynamic);
      window.removeEventListener("fillViewport", handleFillViewport);
      window.removeEventListener("setSeries", handleSetSeries);
      window.removeEventListener("playSpeedUp", handlePlaySpeedUp);
      window.removeEventListener("playSpeedDown", handlePlaySpeedDown);
      window.removeEventListener("playStop", handlePlayStop);
      window.removeEventListener("bookmark", handleBookmark);

      window.removeEventListener("loaded" + elementId, handleLoaded);
    };
  }, [
    handleReset,
    handleResize,
    handleInvert,
    handleFixedZoom,
    handleFixedWindow,
    handleSetDefaultWindow,
    handleSetFullDynamic,
    handleFillViewport,
    handleSetSeries,
    handlePlaySpeedUp,
    handlePlaySpeedDown,
    handlePlayStop,
    handleBookmark,
    handleLoaded,
    elementId,
  ]);

  /* Load empty Canvas object when WASM is loaded for the first time. */
  React.useEffect(() => {
    if (wasm.ready && wasm.global) {
      if (typeof canvas === "undefined") {
        const value = new WasmCanvas(wasm.global);
        value.init(elementId);
        setCanvas(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasm]);

  /* Load new series when wadoInstances value change. */
  const loadSeries = React.useCallback(
    async (query: Object[]): Promise<void> => {
      try {
        setSpinner(true);
        canvas?.reload(elementId);

        // setup actual control configuration
        canvas?.setTool(app.toolMode);
        canvas?.setAnnotations(app.annotations);
        canvas?.setDicomOverlay(app.dicomOverlay);
        canvas?.setMeasures(app.measures);

        // download series
        if (app.pacsUrl && app.wadoRsEndpoint) {
          await canvas?.query(query);
          await canvas?.download(app.pacsUrl + app.wadoRsEndpoint, true);
        } else {
          console.error("Undefined PACS path for WADO query.");
        }
      } catch (err) {
        console.error(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvas, app]
  );

  React.useEffect(() => {
    if (canvas && wadoInstances.length !== 0) {
      app.setSelected?.(index);
      loadSeries?.(wadoInstances);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, wadoInstances]);

  /* App buttons */
  React.useEffect(() => {
    canvas?.setTool(app.toolMode);
  }, [app.toolMode, canvas]);

  React.useEffect(() => {
    canvas?.setAnnotations(app.annotations);
  }, [app.annotations, canvas]);

  React.useEffect(() => {
    canvas?.setDicomOverlay(app.dicomOverlay);
  }, [app.dicomOverlay, canvas]);

  React.useEffect(() => {
    canvas?.setMeasures(app.measures);
  }, [app.measures, canvas]);

  React.useEffect(() => {
    if (app.boxes[index]) canvas?.invert();
  }, [app.boxes, index, canvas]);

  React.useEffect(() => {
    canvas?.fillViewport();
    setFontAndSize(calcFontSizeLabel(getSize(canvasRef)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.gridView, dimensions, canvas, canvasRef]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "series",
    drop: (src: Object[]) => setWadoInstances(src),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  React.useEffect(() => {
    canvas?.fillViewport();
    setFontAndSize(calcFontSizeLabel(getSize(canvasRef)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.print.enabled, canvas, canvasRef]);

  return (
    <RootDiv
      ref={drop}
      isActive={canDrop && isOver}
      selected={selected && !app.print.enabled}
    >
      {/* TOP LEFT */}
      <Annotations
        position={1}
        fontAndSize={fontAndSize}
        id={"annotations-top-left-" + elementId}
      />

      {/* TOP LEFT */}
      <BookmarkDiv
        fontAndSize={fontAndSize}
        id={"annotations-bookmark-" + elementId}
      >
        <RiBookmarkFill />
      </BookmarkDiv>

      {/* TOP RIGHT */}
      <Annotations
        position={2}
        fontAndSize={fontAndSize}
        id={"annotations-top-right-" + elementId}
      />

      {/* BOTTOM LEFT */}
      <Annotations
        position={3}
        fontAndSize={fontAndSize}
        id={"annotations-bottom-left-" + elementId}
      />

      {/* BOTTOM RIGHT */}
      <Annotations
        position={4}
        fontAndSize={fontAndSize}
        id={"annotations-bottom-right-" + elementId}
      />

      {/* POINTER */}
      <Annotations
        position={5}
        fontAndSize={fontAndSize}
        id={"annotations-pointer-" + elementId}
      />

      {/* TOP CENTER */}
      <Annotations
        position={6}
        fontAndSize={fontAndSize}
        id={"annotations-top-center-" + elementId}
      />

      {/* BOTTOM CENTER */}
      <Annotations
        position={7}
        fontAndSize={fontAndSize}
        id={"annotations-bottom-center-" + elementId}
      />

      {/* RIGHT CENTER */}
      <Annotations
        position={8}
        fontAndSize={fontAndSize}
        id={"annotations-right-center-" + elementId}
      />

      {/* LEFT CENTER */}
      <Annotations
        position={9}
        fontAndSize={fontAndSize}
        id={"annotations-left-center-" + elementId}
      />

      {/* MAIN CANVAS */}
      <Canvas
        ref={canvasRef}
        key={elementId}
        id={elementId}
        onMouseDown={() => app.setSelected?.(index)}
      ></Canvas>

      {spinner && (
        <SpinnerDiv>
          <Spinner></Spinner>
        </SpinnerDiv>
      )}
    </RootDiv>
  );
};

export default Viewport;
