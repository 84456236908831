import { RiRulerFill, RiDeleteBinLine } from "react-icons/ri";
import { IoEllipse } from "react-icons/io5";
import { TbAngle } from "react-icons/tb";
import styled, { css } from "styled-components";

import { Button } from "./index";
import { Tool } from "../../../global";

export const measuresIcons = {
  rule: RiRulerFill,
  area: IoEllipse,
  angle: TbAngle,
  trash: RiDeleteBinLine,
};

const MeasuresModalDiv = styled.div`
    position: fixed;
    z-index: 2;
    top: 52px;
    border-radius: 5px;
    background-color: #016060;
    transition: all 100ms ease-in;
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    border: 2px solid #003030;

    ${(props: { show?: boolean }) => {
      if (props.show) {
        return css`
          visibility: visible;
          opacity: 1;
        `;
      } else {
        return css`
          visibility: hidden;
          opacity: 0;
        `;
      }
    }}}
`;

interface MeasuresModalProps {
  show: boolean;
  onChange: (tool: Tool) => void;
}

export const MeasuresModal = (props: MeasuresModalProps) => {
  return (
    <MeasuresModalDiv show={props.show}>
      <Button
        icon={[measuresIcons.rule]}
        onClick={() => props.onChange(Tool.Rule)}
      />
      <Button
        icon={[measuresIcons.area]}
        onClick={() => props.onChange(Tool.ROI)}
      />
      <Button
        icon={[measuresIcons.angle]}
        onClick={() => props.onChange(Tool.Angle)}
      />
      <Button
        icon={[measuresIcons.trash]}
        onClick={() => props.onChange(Tool.Trash)}
      />
    </MeasuresModalDiv>
  );
};
