import React from "react";
import {
  RiGridFill,
  RiContrastFill,
  RiZoomInLine,
  RiDragMove2Fill,
  RiArrowGoBackFill,
  RiDownload2Fill,
  RiPencilRuler2Fill,
  RiStackFill,
  RiPlayFill,
  RiPrinterFill,
  RiBookmarkLine,
  RiSave2Fill,
} from "react-icons/ri";
import { TbArrowsMaximize } from "react-icons/tb";
import { BiCog } from "react-icons/bi";
import { IconType } from "react-icons";
import styled, { css } from "styled-components";
import useEventListener from "@utilityjs/use-event-listener";

import { Tool, GridView } from "../../../global";
import { AppContext, AppObj } from "../../../context";

import { UserIcon } from "./userIcon";
import { GridModal } from "./gridModal";
import { TextModal } from "./textModal";
import { MeasuresModal, measuresIcons } from "./measuresModal";

import { ContextMenu } from "./contextMenu";

const RootDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 4px;
  padding-top: 4px;
  height: 40px;
  padding-left: 1.25rem;
  padding-right: 0.75rem;
  background-color: #016060;
  border-bottom: 4px solid black;
  position: relative;
  justify-content: space-between;
  user-select: none;

  ${({ print }: { print: boolean }) =>
    print &&
    css`
      display: none;
    `}
`;

const BlockDiv = styled.div`
  display: flex;
  align-items: center;

  ${({ justifyContent }: { justifyContent?: string }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
`;

const TitleDiv = styled.div`
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  color: white;
`;

const MessageDiv = styled.div`
  min-width: 200px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  background-color: red;
  color: white;
  padding: 6px;
  margin-left: 20px;
  border-radius: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 9px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 5px;
  background-color: #016060;
  color: white;

  :hover {
    background-color: #218080;
    transition: background-color 0.5s ease;
  }

  :active {
    opacity: 0.8;
  }

  ${(props: { selected?: boolean }) =>
    props.selected &&
    css`
      background-color: #218080;

      :hover {
        opacity: 0.9;
        transition: background-color 0.5s ease;
      }
    `}
`;

const DivisionDiv = styled.div`
  height: 30px;
  width: 2px;
  margin-left: 1px;
  margin-right: 1px;
  background-color: white;
`;

const UserDiv = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  padding: 9px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 50%;
  background-color: #015050;
  color: white;

  :hover {
    background-color: #218080;
  }
`;

const Left = () => {
  return (
    <BlockDiv>
      <TitleDiv>
        <span>PROTEUS</span>
      </TitleDiv>
      <MessageDiv>
        <span>ALPHA RELEASE - INVESTIGATIONAL USE ONLY</span>
      </MessageDiv>
    </BlockDiv>
  );
};

export interface ButtonProps {
  icon: IconType[];
  iconIndex?: number;
  iconNumber?: number;
  selected?: boolean;
  onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
  return (
    <ButtonDiv selected={props.selected} onClick={props.onClick}>
      {React.createElement(props.icon[props.iconIndex || 0], { size: 24 })}
    </ButtonDiv>
  );
};

interface Boxes {
  gridModal: boolean;
  configModal: boolean;
  measuresModal: boolean;
}

const defBoxes: Boxes = {
  gridModal: false,
  configModal: false,
  measuresModal: false,
};

const Center = ({ app }: { app: AppObj }) => {
  const [boxes, setBoxes] = React.useState<Boxes>(defBoxes);
  const [measuresIcon, setMeasureIcon] = React.useState(0);

  /* Keyboard Shortcuts */
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (!app.blockShortcuts) {
        if (app.setToolMode) {
          if (app.shortcuts.browseSeries.test(event)) {
            app.setToolMode(Tool.Browse);
            setMeasureIcon(0);
          }

          if (app.shortcuts.imageWindow.test(event)) {
            app.setToolMode(Tool.Window);
            setMeasureIcon(0);
          }

          if (app.shortcuts.panImage.test(event)) {
            app.setToolMode(Tool.Pan);
            setMeasureIcon(0);
          }

          if (app.shortcuts.zoomImage.test(event)) {
            app.setToolMode(Tool.Zoom);
            setMeasureIcon(0);
          }

          if (app.shortcuts.rule.test(event)) {
            app.setToolMode(Tool.Rule);
            setMeasureIcon(1);
          }

          if (app.shortcuts.roi.test(event)) {
            app.setToolMode(Tool.ROI);
            setMeasureIcon(2);
          }

          if (app.shortcuts.angle.test(event)) {
            app.setToolMode(Tool.Angle);
            setMeasureIcon(3);
          }

          if (app.shortcuts.trash.test(event)) {
            app.setToolMode(Tool.Trash);
            setMeasureIcon(4);
          }
        }

        if (app.setGridView) {
          if (app.shortcuts.firstGridView.test(event)) {
            app.setGridView(app.fixedGridViews.first);
            app.setSelected?.(1);
          }

          if (app.shortcuts.secondGridView.test(event)) {
            app.setGridView(app.fixedGridViews.second);
            app.setSelected?.(2);
          }

          if (app.shortcuts.thirdGridView.test(event)) {
            app.setGridView(app.fixedGridViews.third);
            app.setSelected?.(3);
          }

          if (app.shortcuts.fourthGridView.test(event)) {
            app.setGridView(app.fixedGridViews.fourth);
            app.setSelected?.(4);
          }

          if (app.shortcuts.fifthGridView.test(event)) {
            app.setGridView(app.fixedGridViews.fifth);
            app.setSelected?.(6);
          }

          if (app.shortcuts.sixthGridView.test(event)) {
            app.setGridView(app.fixedGridViews.sixth);
            app.setSelected?.(8);
          }

          if (app.shortcuts.seventhGridView.test(event)) {
            app.setGridView(app.fixedGridViews.seventh);
            app.setSelected?.(9);
          }

          if (app.shortcuts.eighthGridView.test(event)) {
            app.setGridView(app.fixedGridViews.eighth);
            app.setSelected?.(16);
          }
        }

        if (app.shortcuts.playStop.test(event)) {
          const event = new CustomEvent("playStop");
          window.dispatchEvent(event);
        }

        if (app.shortcuts.playSpeedUp.test(event)) {
          const event = new CustomEvent("playSpeedUp");
          window.dispatchEvent(event);
        }

        if (app.shortcuts.playSpeedDown.test(event)) {
          const event = new CustomEvent("playSpeedDown");
          window.dispatchEvent(event);
        }
      }
    },
    [app]
  );

  useEventListener({
    target: window,
    eventType: "keydown",
    handler: handleKeyDown,
  });

  /* Mouse Events */
  const handleMouseLeave = React.useCallback(
    () => setBoxes(defBoxes),
    [setBoxes]
  );

  useEventListener({
    target: document.getElementById("menu"),
    eventType: "mouseleave",
    handler: handleMouseLeave,
  });

  return (
    <BlockDiv justifyContent={"center"}>
      <div>
        <Button
          icon={[RiGridFill]}
          selected={boxes.gridModal}
          onClick={() => {
            if (boxes.gridModal) {
              setBoxes(defBoxes);
            } else {
              setBoxes({
                gridModal: true,
                configModal: false,
                measuresModal: false,
              });
            }
          }}
        />
        <GridModal
          show={boxes.gridModal}
          onChange={(gridView: GridView): void => {
            setBoxes(defBoxes);
            if (app.setGridView) app.setGridView(gridView);
          }}
        />
      </div>
      <DivisionDiv />
      <Button
        icon={[RiStackFill]}
        selected={app.toolMode === Tool.Browse}
        onClick={() => {
          setBoxes(defBoxes);
          if (app.setToolMode) app.setToolMode(Tool.Browse);
          setMeasureIcon(0);
        }}
      />
      <Button
        icon={[RiContrastFill]}
        selected={app.toolMode === Tool.Window}
        onClick={() => {
          setBoxes(defBoxes);
          if (app.setToolMode) app.setToolMode(Tool.Window);
          setMeasureIcon(0);
        }}
      />
      <Button
        icon={[RiDragMove2Fill]}
        selected={app.toolMode === Tool.Pan}
        onClick={() => {
          setBoxes(defBoxes);
          if (app.setToolMode) app.setToolMode(Tool.Pan);
          setMeasureIcon(0);
        }}
      />
      <Button
        icon={[RiZoomInLine]}
        selected={app.toolMode === Tool.Zoom}
        onClick={() => {
          setBoxes(defBoxes);
          if (app.setToolMode) app.setToolMode(Tool.Zoom);
          setMeasureIcon(0);
        }}
      />
      <div>
        <Button
          icon={[
            RiPencilRuler2Fill,
            measuresIcons.rule,
            measuresIcons.area,
            measuresIcons.angle,
            measuresIcons.trash,
          ]}
          iconIndex={measuresIcon}
          selected={
            boxes.measuresModal ||
            app.toolMode === Tool.Rule ||
            app.toolMode === Tool.ROI ||
            app.toolMode === Tool.Angle ||
            app.toolMode === Tool.Trash
          }
          onClick={() => {
            if (boxes.measuresModal) {
              setBoxes(defBoxes);
            } else {
              setBoxes({
                gridModal: false,
                configModal: false,
                measuresModal: true,
              });
            }
          }}
        />
        <MeasuresModal
          show={boxes.measuresModal}
          onChange={(tool: Tool): void => {
            setBoxes(defBoxes);
            if (app.setToolMode) {
              if (tool === Tool.Rule) {
                setMeasureIcon(1);
              } else if (tool === Tool.ROI) {
                setMeasureIcon(2);
              } else if (tool === Tool.Angle) {
                setMeasureIcon(3);
              } else if (tool === Tool.Trash) {
                setMeasureIcon(4);
              }
              app.setToolMode(tool);
            }
          }}
        />
      </div>
      <DivisionDiv />
      <Button
        icon={[RiPlayFill]}
        onClick={() => {
          const event = new CustomEvent("playStop");
          window.dispatchEvent(event);
        }}
      />
      <Button
        icon={[RiArrowGoBackFill]}
        onClick={() => {
          setBoxes(defBoxes);
          const event = new CustomEvent("reset");
          window.dispatchEvent(event);
        }}
      />
      <div>
        <Button
          icon={[BiCog]}
          selected={boxes.configModal}
          onClick={() => {
            if (boxes.configModal) {
              setBoxes(defBoxes);
            } else {
              setBoxes({
                gridModal: false,
                configModal: true,
                measuresModal: false,
              });
            }
          }}
        />
        <TextModal
          show={boxes.configModal}
          onChange={({
            annotations,
            dicomOverlay,
            measures,
          }: {
            annotations: boolean;
            dicomOverlay: boolean;
            measures: boolean;
          }): void => {
            setBoxes(defBoxes);
            if (app.setAnnotations) app.setAnnotations(annotations);
            if (app.setDicomOverlay) app.setDicomOverlay(dicomOverlay);
            if (app.setMeasures) app.setMeasures(measures);
          }}
        />
      </div>
      <Button
        icon={[RiDownload2Fill]}
        onClick={() => {
          setBoxes(defBoxes);
          console.log("download");
        }}
      />
      <Button
        icon={[RiPrinterFill]}
        onClick={() => {
          if (app.setPrint)
            app.setPrint((prevState) => ({ ...prevState, enabled: true }));
        }}
      />
      {app.permissions.bookmarks.update && (
        <Button
          icon={[RiBookmarkLine]}
          onClick={() => {
            const event = new CustomEvent("bookmark");
            window.dispatchEvent(event);
          }}
        />
      )}
      {(app.permissions.bookmarks.update ||
        app.permissions.measures.update) && (
        <Button
          icon={[RiSave2Fill]}
          onClick={() => {
            const event = new CustomEvent("save");
            window.dispatchEvent(event);
          }}
        />
      )}
    </BlockDiv>
  );
};

const reqFullscreen = (element: any) => {
  if (element.requestFullscreen) element.requestFullscreen();
  else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
  else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
  else if (element.msRequestFullscreen) element.msRequestFullscreen();
};

const exitFullscreen = (element: any) => {
  if (element.exitFullscreen) element.exitFullscreen();
  else if (element.mozCancelFullScreen) element.mozCancelFullScreen();
  else if (element.webkitExitFullscreen) element.webkitExitFullscreen();
  else if (element.msExitFullscreen) element.msExitFullscreen();
};

const isFullscreen = (element: any) => {
  var full_screen_element =
    element.fullscreenElement ||
    element.webkitFullscreenElement ||
    element.mozFullScreenElement ||
    element.msFullscreenElement ||
    null;

  if (full_screen_element === null) return false;
  else return true;
};

const Right = ({ app }: { app: AppObj }) => {
  return (
    <BlockDiv justifyContent={"flex-end"}>
      <Button
        icon={[TbArrowsMaximize]}
        onClick={() => {
          if (isFullscreen(document)) {
            exitFullscreen(document);
          } else {
            reqFullscreen(document.body);
          }
        }}
      />
      <UserDiv>
        <UserIcon
          user={app.user.id === "default" ? undefined : app.user.id}
          size={24}
        />
      </UserDiv>
    </BlockDiv>
  );
};

const TopBar = () => {
  const app = React.useContext(AppContext);

  return (
    <RootDiv id="menu" print={app.print.enabled}>
      {/* Floating Context Menu */}
      <ContextMenu />

      {/* Buttons */}
      <Left />
      <Center app={app} />
      <Right app={app} />
    </RootDiv>
  );
};

export default TopBar;
