import React from "react";
import axios from "axios";

import {
  Tool,
  GridView,
  defToolMode,
  defGridView,
  Box,
  defBoxes,
  WasmGlobal,
  getURLParameter,
  Shortcuts,
  defShortcuts,
  defFixedWindows,
  defFixedZooms,
  FixedWidows,
  FixedZooms,
  FixedGridViews,
  defFixedGridViews,
  shortcutsFromUser,
  fixedGridViewsFromUser,
  fixedWindowsFromUser,
  fixedZoomsFromUser,
  defUser,
  User,
  Print,
  defPrint,
  Permissions,
  defPermissions,
  permissionsFromUser,
  readToken,
} from "./global";
import { Study } from "./dicom";

import {
  SnackbarProps,
  SnackbarType,
  defSnackbarProps,
} from "./components/view/alertSnackbar";

import init, {
  setup_logs as wSetupLogs,
  get_version as wGetVersion,
} from "./wasm";

import { J2KDecoder } from "./j2k";

import {
  HangingProtocols,
  defHangingProtocols,
  hangingProtocolsFromUser,
} from "./hangingProtocols";

export interface PDFDecoder {
  pdfjsLib: any;
  cache: any;
  pdfdecode: (bytes: Uint8Array) => Promise<void>;
  pdfpages: () => number;
  pdfdrawpage: (pageNo: number) => Promise<ImageData | undefined>;
}

export class PDFDecoder {
  constructor() {
    this.pdfjsLib = require("pdfjs-dist/webpack");
    this.cache = null;

    this.pdfdecode = async (bytes: Uint8Array): Promise<void> => {
      var loadingTask = this.pdfjsLib.getDocument({ data: bytes.buffer });

      if (bytes !== null) this.cache = await loadingTask.promise;
    };

    this.pdfpages = (): number => {
      return this.cache.numPages;
    };

    this.pdfdrawpage = async (
      pageNo: number
    ): Promise<ImageData | undefined> => {
      var page = await this.cache.getPage(pageNo);

      var viewport = page.getViewport({ scale: 1.5 });

      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context.
      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;

      return context?.getImageData(0, 0, viewport.width, viewport.height);
    };
  }
}

declare global {
  interface Window {
    J2KDecoder: J2KDecoder;
    PDFDecoder: PDFDecoder;
  }
}

/* AlertContext */

interface AlertObj {
  snackbar: SnackbarProps;
  setAlert?: (message: string) => void;
  setWarning?: (message: string) => void;
  setSuccess?: (message: string) => void;
  hide?: () => void;
}

export const AlertContext: React.Context<AlertObj> =
  React.createContext<AlertObj>({
    snackbar: defSnackbarProps,
  });

export const AlertProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [snackbar, setSnackbar] =
    React.useState<SnackbarProps>(defSnackbarProps);

  const setAlert = (message: string) => {
    setSnackbar({
      show: true,
      type: SnackbarType.Alert,
      message,
    });
  };

  const setWarning = (message: string) => {
    setSnackbar({
      show: true,
      type: SnackbarType.Warning,
      message,
    });
  };

  const setSuccess = (message: string) => {
    setSnackbar({
      show: true,
      type: SnackbarType.Success,
      message,
    });
  };

  const hide = () => {
    setSnackbar({
      ...snackbar,
      show: false,
    });
  };

  const alertContext: AlertObj = {
    snackbar,
    setAlert,
    setWarning,
    setSuccess,
    hide,
  };

  return (
    <AlertContext.Provider value={alertContext}>
      {children}
    </AlertContext.Provider>
  );
};

/* AppContext */

export interface AppObj {
  standalone: boolean;
  pacsUrl: string;
  wadoRsEndpoint: string;
  print: Print;
  user: User;
  studies: Study[];
  toolMode: Tool;
  gridView: GridView;
  selected: number;
  annotations: boolean;
  dicomOverlay: boolean;
  measures: boolean;
  boxes: Box[];
  shortcuts: Shortcuts;
  blockShortcuts: boolean;
  fixedWindows: FixedWidows;
  fixedZooms: FixedZooms;
  fixedGridViews: FixedGridViews;
  hangingProtocols: HangingProtocols;
  permissions: Permissions;
  token: string;
  setStandalone?: React.Dispatch<React.SetStateAction<boolean>>;
  setPacsUrl?: React.Dispatch<React.SetStateAction<string>>;
  setWadoRsEndpoint?: React.Dispatch<React.SetStateAction<string>>;
  setPrint?: React.Dispatch<React.SetStateAction<Print>>;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  setStudies?: React.Dispatch<React.SetStateAction<Study[]>>;
  setToolMode?: React.Dispatch<React.SetStateAction<Tool>>;
  setGridView?: React.Dispatch<React.SetStateAction<GridView>>;
  setSelected?: React.Dispatch<React.SetStateAction<number>>;
  setAnnotations?: React.Dispatch<React.SetStateAction<boolean>>;
  setDicomOverlay?: React.Dispatch<React.SetStateAction<boolean>>;
  setMeasures?: React.Dispatch<React.SetStateAction<boolean>>;
  setBoxes?: React.Dispatch<React.SetStateAction<Box[]>>;
  setShortcuts?: React.Dispatch<React.SetStateAction<Shortcuts>>;
  setBlockShortcuts?: React.Dispatch<React.SetStateAction<boolean>>;
  setFixedWindows?: React.Dispatch<React.SetStateAction<FixedWidows>>;
  setFixedZooms?: React.Dispatch<React.SetStateAction<FixedZooms>>;
  setFixedGridViews?: React.Dispatch<React.SetStateAction<FixedGridViews>>;
  setToken?: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext: React.Context<AppObj> = React.createContext<AppObj>({
  standalone: true,
  pacsUrl: "",
  wadoRsEndpoint: "",
  print: defPrint,
  user: defUser,
  studies: [],
  toolMode: defToolMode,
  gridView: defGridView,
  selected: 1,
  annotations: true,
  dicomOverlay: true,
  measures: true,
  boxes: [],
  shortcuts: defShortcuts,
  blockShortcuts: false,
  fixedWindows: defFixedWindows,
  fixedZooms: defFixedZooms,
  fixedGridViews: defFixedGridViews,
  hangingProtocols: defHangingProtocols,
  permissions: defPermissions,
  token: "",
});

export const AppProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const userId = getURLParameter("user") || "default";

  // main config
  const [standalone, setStandalone] = React.useState<boolean>(true);
  const [pacsUrl, setPacsUrl] = React.useState<string>("");
  const [wadoRsEndpoint, setWadoRsEndpoint] = React.useState<string>("");

  // user config
  const [print, setPrint] = React.useState<Print>(defPrint);
  const [user, setUser] = React.useState<User>(defUser);
  const [studies, setStudies] = React.useState<Study[]>([]);
  const [toolMode, setToolMode] = React.useState<Tool>(defToolMode);
  const [gridView, setGridView] = React.useState<GridView>(defGridView);
  const [selected, setSelected] = React.useState<number>(1);
  const [annotations, setAnnotations] = React.useState<boolean>(true);
  const [dicomOverlay, setDicomOverlay] = React.useState<boolean>(true);
  const [measures, setMeasures] = React.useState<boolean>(true);
  const [boxes, setBoxes] = React.useState<Box[]>(defBoxes());
  const [shortcuts, setShortcuts] = React.useState<Shortcuts>(defShortcuts);
  const [blockShortcuts, setBlockShortcuts] = React.useState<boolean>(false);
  const [fixedWindows, setFixedWindows] =
    React.useState<FixedWidows>(defFixedWindows);
  const [fixedZooms, setFixedZooms] = React.useState<FixedZooms>(defFixedZooms);
  const [fixedGridViews, setFixedGridViews] =
    React.useState<FixedGridViews>(defFixedGridViews);
  const [hangingProtocols, setHangingProtocols] =
    React.useState<HangingProtocols>(defHangingProtocols);
  const [permissions, setPermissions] =
    React.useState<Permissions>(defPermissions);
  const [token, setToken] = React.useState<string>(readToken(userId));

  const appContext: AppObj = {
    standalone,
    pacsUrl,
    wadoRsEndpoint,
    print,
    user,
    studies,
    toolMode,
    gridView,
    selected,
    annotations,
    dicomOverlay,
    measures,
    boxes,
    shortcuts,
    blockShortcuts,
    fixedWindows,
    fixedZooms,
    fixedGridViews,
    hangingProtocols,
    permissions,
    token,
    setStandalone,
    setPacsUrl,
    setWadoRsEndpoint,
    setPrint,
    setUser,
    setStudies,
    setToolMode,
    setGridView,
    setSelected,
    setAnnotations,
    setDicomOverlay,
    setMeasures,
    setBoxes,
    setShortcuts,
    setBlockShortcuts,
    setFixedWindows,
    setFixedZooms,
    setFixedGridViews,
    setToken,
  };

  const handleAfterPrint = React.useCallback((): void => {
    setPrint((prevState) => ({ ...prevState, enabled: false }));
  }, [setPrint]);

  React.useEffect((): void => {
    if (print.enabled) {
      window.addEventListener("afterprint", handleAfterPrint);
      window.print();
      window.removeEventListener("afterprint", handleAfterPrint);
    }
  }, [handleAfterPrint, print]);

  React.useEffect(() => {
    const pathConfig =
      process.env.NODE_ENV === "development"
        ? "/config"
        : process.env.PUBLIC_URL + "/config";

    axios
      .get(pathConfig)
      .then(({ data }) => {
        let isStandalone = true;

        if (typeof data.standalone !== "undefined") {
          isStandalone = data.standalone;
          setStandalone(data.standalone);
        } else {
          console.log("Missing standalone value in config JSON.");
        }

        if (typeof data.pacsUrl !== "undefined") {
          const pacs = getURLParameter("pacs");

          if (pacs) {
            setPacsUrl(pacs);
          } else {
            setPacsUrl(data.pacsUrl);
          }
        } else {
          console.log("Missing pacsUrl value in config JSON.");
        }

        if (typeof data.wadoRsEndpoint !== "undefined") {
          const wadors = getURLParameter("wadors");

          if (wadors) {
            setWadoRsEndpoint(wadors);
          } else {
            setWadoRsEndpoint(data.wadoRsEndpoint);
          }
        } else {
          console.log("Missing wadoRsEndpoint value in config JSON.");
        }

        if (!isStandalone) {
          axios
            .get("/api/users/" + userId + "/config")
            .then(({ data }) => {
              if (data) {
                if (typeof data.fixedZooms !== "undefined")
                  setFixedZooms(fixedZoomsFromUser(data.fixedZooms));

                if (typeof data.fixedWindows !== "undefined")
                  setFixedWindows(fixedWindowsFromUser(data.fixedWindows));

                if (typeof data.fixedGridViews !== "undefined")
                  setFixedGridViews(
                    fixedGridViewsFromUser(data.fixedGridViews)
                  );

                if (typeof data.shorcuts !== "undefined")
                  setShortcuts(shortcutsFromUser(data.shorcuts));

                if (typeof data.hangingProtocols !== "undefined")
                  setHangingProtocols(
                    hangingProtocolsFromUser(data.hangingProtocols)
                  );

                if (typeof data.permissions !== "undefined")
                  setPermissions(permissionsFromUser(data.permissions));

                let name = undefined;
                let surname = undefined;

                if (typeof data.name !== "undefined") name = data.name;
                if (typeof data.surname !== "undefined") surname = data.surname;

                setUser({
                  id: userId,
                  name,
                  surname,
                });
              }
            })
            .catch(console.error);
        }
      })
      .catch((error) => console.error(error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};

/* WasmContext */

interface WasmObj {
  ready: boolean;
  global?: WasmGlobal;
}

export const WasmContext: React.Context<WasmObj> = React.createContext<WasmObj>(
  {
    ready: false,
  }
);

export const WasmProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [ready, setReady] = React.useState<boolean>(false);
  const [global, setGlobal] = React.useState<WasmGlobal>();

  const wasmContext: WasmObj = {
    ready,
    global,
  };

  React.useEffect(() => {
    const pacsToken = getURLParameter("auth");

    window.J2KDecoder = new J2KDecoder();
    window.PDFDecoder = new PDFDecoder();

    init().then(() => {
      console.log("Core v." + wGetVersion());
      wSetupLogs();

      const wasmGlobal = new WasmGlobal(pacsToken ? pacsToken : "");
      setGlobal(wasmGlobal);

      setReady(true);
    });
  }, []);

  return (
    <WasmContext.Provider value={wasmContext}>{children}</WasmContext.Provider>
  );
};
