import React from "react";
import styled, { css } from "styled-components";
import { getURLParameter } from "../../../global";
import { Study, Series, getStudy, getSeriesMetadata } from "../../../dicom";

import {
  AlertContext,
  AppContext,
  WasmContext,
} from "../../../context";
import { SOPS } from "../../../sops";
import { Protocol } from "../../../hangingProtocols";
import Box, { BoxItem } from "./box";

const RootDiv = styled.div`
  height: calc(100vh - 52px - 25px);
  overflow-y: scroll;
  background-color: black;
  float: left;
  width: 170px;

  ${({ print }: { print: boolean }) =>
    print &&
    css`
      display: none;
    `}
`;

export const BoxDiv = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 150px;
  text-align: center;
  color: white;
  user-select: none;
  border-radius: 3px;

  ${(props: {
    patient?: boolean;
    study?: boolean;
    series?: boolean;
    selected?: boolean;
  }) => {
    if (props.patient) {
      return css`
        height: 50px;
        background-color: ${props.selected ? "#510101" : "#015050"};
      `;
    } else if (props.study) {
      return css`
        height: 40px;
        background-color: ${props.selected ? "#510101" : "#015050"};
      `;
    } else if (props.series) {
      return css`
        height: 145px;
        background-color: ${props.selected ? "#510101" : "#015050"};
      `;
    }
  }}}
`;

export const MediumTextDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
  white-space: nowrap;
  font-size: 15px;
  height: 18px;
`;

export const SmallTextDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
  white-space: nowrap;
  font-size: 12px;
`;

const PatientBox = ({ study }: { study: Study }) => {
  const chunks = study.patName.split("^");
  const bod =
    study.patBirthDate.slice(6, 8) +
    "/" +
    study.patBirthDate.slice(4, 6) +
    "/" +
    study.patBirthDate.slice(0, 4);

  return (
    <BoxDiv patient>
      <MediumTextDiv>
        <span>{typeof chunks[0] !== "undefined" ? chunks[0] : null}</span>
      </MediumTextDiv>
      <MediumTextDiv>
        <span>{typeof chunks[1] !== "undefined" ? chunks[1] : null}</span>
      </MediumTextDiv>
      <SmallTextDiv>
        <span>{bod}</span>
      </SmallTextDiv>
    </BoxDiv>
  );
};

const StudyBox = ({ study }: { study: Study }) => {
  const date =
    study.studyDate.slice(6, 8) +
    "/" +
    study.studyDate.slice(4, 6) +
    "/" +
    study.studyDate.slice(0, 4);
  const time =
    study.studyTime.slice(0, 2) +
    ":" +
    study.studyTime.slice(2, 4) +
    ":" +
    study.studyTime.slice(4, 6);

  return (
    <BoxDiv study>
      <SmallTextDiv>
        <span>{date + " " + time}</span>
      </SmallTextDiv>
      <SmallTextDiv>
        <span>{study.studyDesc}</span>
      </SmallTextDiv>
      <SmallTextDiv>
        <span>
          {study.modsInStudy.join(",") + ": " + study.series.length + " series"}
        </span>
      </SmallTextDiv>
    </BoxDiv>
  );
};

const SeriesGroup = ({
  studyIuid,
  series,
  pacsPath,
  protocol,
}: {
  studyIuid: string;
  series: Series;
  pacsPath: string;
  protocol?: Protocol;
}) => {
  const alert = React.useContext(AlertContext);
  const [items, setItems] = React.useState<BoxItem[]>([]);

  const iuids = {
    study: studyIuid,
    series: series.seriesIuid,
    sop: null,
  };

  React.useEffect(() => {
    getSeriesMetadata(pacsPath, iuids)
      .then((ret) => {
        let out: BoxItem[] = [];
        let single: BoxItem | undefined;

        ret.forEach((el) => {
          if (el.parsed.numberOfFrames > 1) {
            out.push({
              studyIuid,
              seriesIuid: series.seriesIuid,
              sopIuid: el.parsed.sopIuid ? el.parsed.sopIuid : "",
              frames: el.parsed.numberOfFrames || series.seriesInstCount,
              description: series.seriesDesc,
              seriesNo: series.seriesNumber,
              pdf: false,
              group: false,
              sops: [el.parsed.sopIuid ? el.parsed.sopIuid : ""],
              wadoInstances: [el.raw],
              pacsPath,
            });
          } else if (single === undefined) {
            single = {
              studyIuid,
              seriesIuid: series.seriesIuid,
              sopIuid: el.parsed.sopIuid ? el.parsed.sopIuid : "",
              frames: el.parsed.numberOfFrames || 1,
              description: series.seriesDesc,
              seriesNo: series.seriesNumber,
              pdf: el.parsed.sopClassUid === SOPS.EncapsulatedPDF,
              group: true,
              sops: [el.parsed.sopIuid ? el.parsed.sopIuid : ""],
              wadoInstances: [el.raw],
              pacsPath,
            };
          } else {
            single.frames = single.frames + 1;
            single.sops.push(el.parsed.sopIuid ? el.parsed.sopIuid : "");
            single.wadoInstances.push(el.raw);
          }

          if (protocol) {
            const viewport = protocol.viewport(el.raw);
            if (viewport) {
            }
          }
        });

        if (single) out.push(single);
        setItems(out);
      })
      .catch((error) => {
        console.log(error);
        if (alert.setAlert)
          alert.setAlert("Could not retrieve series metadata!");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {[...items].map((item, j) => (
        <Box item={item} key={j} />
      ))}
    </React.Fragment>
  );
};

const NavBar = () => {
  const app = React.useContext(AppContext);
  const alert = React.useContext(AlertContext);
  const wasm = React.useContext(WasmContext);

  React.useEffect(() => {
    if (app.pacsUrl && app.wadoRsEndpoint) {
      const ids = getURLParameter("ids");
      const pacsPath = app.pacsUrl + app.wadoRsEndpoint;

      if (ids === undefined) {
        if (alert.setAlert) alert.setAlert("Could not retrieve studies!");
      } else {
        const chunks = ids.split(",");

        for (let i = 0; i < chunks.length; i++) {
          getStudy(pacsPath, chunks[i], app.hangingProtocols)
            .then((study) => {
              if (app.setStudies) {
                const filteredStudies = app.studies.filter(
                  (evt) => evt.studyIuid !== study.studyIuid
                );

                study.pacsPath = pacsPath;

                app.setStudies([...filteredStudies, study]);

                // execute hanging protocols
                if (typeof study.protocol !== "undefined") {
                  if (app.setGridView) {
                    app.setGridView(study.protocol.gridView);
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
              if (alert.setAlert) alert.setAlert("Could not retrieve studies!");
            });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.pacsUrl, app.wadoRsEndpoint, wasm.global]);

  return app.studies.length ? (
    <RootDiv print={app.print.enabled}>
      {[...app.studies].map((study, i) => (
        <div key={i}>
          <PatientBox study={study} />
          <StudyBox study={study} />
          {[...study.series].map((series, j) => (
            <SeriesGroup
              studyIuid={study.studyIuid}
              pacsPath={study.pacsPath}
              protocol={study.protocol}
              series={series}
              key={j}
            />
          ))}
        </div>
      ))}
    </RootDiv>
  ) : (
    <div></div>
  );
};

export default NavBar;
