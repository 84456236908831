import React from "react";
import styled, { css } from "styled-components";

import { AlertContext } from "../../context";

const HIDE_TIMEOUT = 10000;

const RootDiv = styled.div`
  visibility: hidden;
  min-width: 344px;
  margin-left: -172px;
  background-color: #016060;
  text-align: center;
  border-radius: 5px;
  padding: 16px 8px 16px 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;

  :hover {
    opacity: 0.85;
  }

  ${(props: { show: boolean; type: SnackbarType }) =>
    props.show &&
    css`
      visibility: visible;
      -webkit-animation: fadein-snackbar 0.5s;
      animation: fadein-snackbar 0.5s;
      background-color: ${SnackbarBackColor(props.type)};
      color: ${SnackbarTextColor(props.type)};
    `}
`;

export enum SnackbarType {
  Alert = "alert",
  Warning = "warning",
  Success = "success",
}

const SnackbarBackColor = (type: SnackbarType) => {
  switch (type) {
    case SnackbarType.Alert:
      return "#E72B1C";
    case SnackbarType.Warning:
      return "#FFCB05";
    default:
      return "#016060";
  }
};

const SnackbarTextColor = (type: SnackbarType) => {
  switch (type) {
    case SnackbarType.Alert:
      return "white";
    case SnackbarType.Warning:
      return "black";
    default:
      return "white";
  }
};

export interface SnackbarProps {
  show: boolean;
  type: SnackbarType;
  message: string;
}

export const defSnackbarProps: SnackbarProps = {
  show: false,
  type: SnackbarType.Success,
  message: "",
};

const AlertSnackbar = () => {
  const { snackbar, hide } = React.useContext(AlertContext);

  // Auto hide snackbar after HIDE_TIMEOUT milliseconds.
  React.useEffect(() => {
    var timer: NodeJS.Timeout | null = null;

    if (snackbar.show) {
      timer = setTimeout(() => {
        if (hide) hide();
      }, HIDE_TIMEOUT);
    }

    return () => {
      if (timer) clearTimeout(timer);
    }
  }, [snackbar, hide]);

  return (
    <RootDiv
      show={snackbar.show}
      type={snackbar.type}
      onClick={() => {
        if (hide) hide();
      }}
    >
      <span>{snackbar.message}</span>
    </RootDiv>
  );
};

export default AlertSnackbar;
