import React from "react";
import styled, { css } from "styled-components";
import useEventListener from "@utilityjs/use-event-listener";

import { AppContext } from "../../../context";
import { TextCheckButton } from "./global";

interface TextModalProps {
  show: boolean;
  onChange: ({
    annotations,
    dicomOverlay,
    measures,
  }: {
    annotations: boolean;
    dicomOverlay: boolean;
    measures: boolean;
  }) => void;
}

const TextModalDiv = styled.div`
    position: fixed;
    z-index: 2;
    top: 52px;
    width: 200px;
    border-radius: 5px;
    background-color: #016060;
    transition: all 100ms ease-in;
    padding: 5px;
    border: 2px solid #003030;

    ${(props: { show?: boolean }) => {
      if (props.show) {
        return css`
          visibility: visible;
          opacity: 1;
        `;
      } else {
        return css`
          visibility: hidden;
          opacity: 0;
        `;
      }
    }}}
`;

export const TextModal = (props: TextModalProps) => {
  const app = React.useContext(AppContext);

  const [config, setConfig] = React.useState({
    annotations: true,
    dicomOverlay: true,
    measures: true,
  });

  const changeAnnotations = React.useCallback(() => {
    const change = {
      annotations: !config.annotations,
      dicomOverlay: config.dicomOverlay,
      measures: config.measures,
    };

    setConfig(change);
    props.onChange(change);
  }, [config.annotations, config.dicomOverlay, config.measures, props]);

  const changeDicomOverlay = React.useCallback(() => {
    const change = {
      annotations: config.annotations,
      dicomOverlay: !config.dicomOverlay,
      measures: config.measures,
    };

    setConfig(change);
    props.onChange(change);
  }, [config.annotations, config.dicomOverlay, config.measures, props]);

  const changeMeasures = React.useCallback(() => {
    const change = {
      annotations: config.annotations,
      dicomOverlay: config.dicomOverlay,
      measures: !config.measures,
    };

    setConfig(change);
    props.onChange(change);
  }, [config.annotations, config.dicomOverlay, config.measures, props]);

  /* Keyboard Shortcuts */
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (!app.blockShortcuts) {
        if (app.shortcuts.annotations.test(event)) changeAnnotations();
        if (app.shortcuts.dicomOverlay.test(event)) changeDicomOverlay();
        if (app.shortcuts.measures.test(event)) changeMeasures();
      }
    },
    [app, changeAnnotations, changeDicomOverlay, changeMeasures]
  );

  useEventListener({
    target: window,
    eventType: "keydown",
    handler: handleKeyDown,
  });

  return (
    <TextModalDiv show={props.show}>
      <TextCheckButton
        label={"Annotations"}
        checked={config.annotations}
        shortcut={app.shortcuts.annotations.label()}
        onClick={() => changeAnnotations()}
      />
      <TextCheckButton
        label={"DICOM Overlay"}
        checked={config.dicomOverlay}
        shortcut={app.shortcuts.dicomOverlay.label()}
        onClick={() => changeDicomOverlay()}
      />
      <TextCheckButton
        label={"Measures"}
        checked={config.measures}
        shortcut={app.shortcuts.measures.label()}
        onClick={() => changeMeasures()}
      />
    </TextModalDiv>
  );
};
