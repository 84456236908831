import React from "react";
import styled, { css } from "styled-components";

import { GridView, defGridView } from "../../../global";

const GridModalDiv = styled.div`
  position: fixed;
  z-index: 2;
  top: 52px;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  background-color: #016060;
  transition: all 100ms ease-in;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  padding: 2px;
  border: 2px solid #003030;

  ${(props: { show?: boolean }) => {
    if (props.show) {
      return css`
        visibility: visible;
        opacity: 1;
      `;
    } else {
      return css`
        visibility: hidden;
        opacity: 0;
      `;
    }
  }}}
`;

const GridModalItemDiv = styled.div`
  background-color: #014040;
  margin: 2px;
  opacity: 0.95;

  ${(props: {
    active: boolean;
    topLeft?: boolean;
    topRight?: boolean;
    bottomRight?: boolean;
    bottomLeft?: boolean;
  }) => {
    if (props.active) {
      if (props.topLeft) {
        return css`
          margin: 2px;
          background-color: white;
          border-radius: 3px 0 0 0;
        `;
      } else if (props.topRight) {
        return css`
          margin: 2px;
          background-color: white;
          border-radius: 0 3px 0 0;
        `;
      } else if (props.bottomRight) {
        return css`
          margin: 2px;
          background-color: white;
          border-radius: 0 0 3px 0px;
        `;
      } else if (props.bottomLeft) {
        return css`
          margin: 2px;
          background-color: white;
          border-radius: 0 0 0 3px;
        `;
      } else {
        return css`
          margin: 2px;
          background-color: white;
        `;
      }
    } else {
      if (props.topLeft) {
        return css`
          border-radius: 3px 0 0 0;
        `;
      } else if (props.topRight) {
        return css`
          border-radius: 0 3px 0 0;
        `;
      } else if (props.bottomRight) {
        return css`
          border-radius: 0 0 3px 0px;
        `;
      } else if (props.bottomLeft) {
        return css`
          border-radius: 0 0 0 3px;
        `;
      }
    }
  }}
`;

interface GridModalProps {
  show: boolean;
  onChange: (gridView: GridView) => void;
}

export const GridModal = (props: GridModalProps) => {
  const [gridView, setGridView] = React.useState<GridView>(defGridView);

  const isActive = (rows: number, cols: number): boolean => {
    return gridView.rows >= rows && gridView.cols >= cols;
  };

  const gridMouseEnter = (rows: number, cols: number) => {
    setGridView({ rows, cols });
  };

  const gridClick = (rows: number, cols: number) => {
    props.onChange({ rows, cols });
  };

  return (
    <GridModalDiv show={props.show}>
      <GridModalItemDiv
        active={isActive(1, 1)}
        onMouseEnter={() => gridMouseEnter(1, 1)}
        onClick={() => gridClick(1, 1)}
        topLeft
      />
      <GridModalItemDiv
        active={isActive(1, 2)}
        onMouseEnter={() => gridMouseEnter(1, 2)}
        onClick={() => gridClick(1, 2)}
      />
      <GridModalItemDiv
        active={isActive(1, 3)}
        onMouseEnter={() => gridMouseEnter(1, 3)}
        onClick={() => gridClick(1, 3)}
      />
      <GridModalItemDiv
        active={isActive(1, 4)}
        onMouseEnter={() => gridMouseEnter(1, 4)}
        onClick={() => gridClick(1, 4)}
        topRight
      />
      <GridModalItemDiv
        active={isActive(2, 1)}
        onMouseEnter={() => gridMouseEnter(2, 1)}
        onClick={() => gridClick(2, 1)}
      />
      <GridModalItemDiv
        active={isActive(2, 2)}
        onMouseEnter={() => gridMouseEnter(2, 2)}
        onClick={() => gridClick(2, 2)}
      />
      <GridModalItemDiv
        active={isActive(2, 3)}
        onMouseEnter={() => gridMouseEnter(2, 3)}
        onClick={() => gridClick(2, 3)}
      />
      <GridModalItemDiv
        active={isActive(2, 4)}
        onMouseEnter={() => gridMouseEnter(2, 4)}
        onClick={() => gridClick(2, 4)}
      />
      <GridModalItemDiv
        active={isActive(3, 1)}
        onMouseEnter={() => gridMouseEnter(3, 1)}
        onClick={() => gridClick(3, 1)}
      />
      <GridModalItemDiv
        active={isActive(3, 2)}
        onMouseEnter={() => gridMouseEnter(3, 2)}
        onClick={() => gridClick(3, 2)}
      />
      <GridModalItemDiv
        active={isActive(3, 3)}
        onMouseEnter={() => gridMouseEnter(3, 3)}
        onClick={() => gridClick(3, 3)}
      />
      <GridModalItemDiv
        active={isActive(3, 4)}
        onMouseEnter={() => gridMouseEnter(3, 4)}
        onClick={() => gridClick(3, 4)}
      />
      <GridModalItemDiv
        active={isActive(4, 1)}
        onMouseEnter={() => gridMouseEnter(4, 1)}
        onClick={() => gridClick(4, 1)}
        bottomLeft
      />
      <GridModalItemDiv
        active={isActive(4, 2)}
        onMouseEnter={() => gridMouseEnter(4, 2)}
        onClick={() => gridClick(4, 2)}
      />
      <GridModalItemDiv
        active={isActive(4, 3)}
        onMouseEnter={() => gridMouseEnter(4, 3)}
        onClick={() => gridClick(4, 3)}
      />
      <GridModalItemDiv
        active={isActive(4, 4)}
        onMouseEnter={() => gridMouseEnter(4, 4)}
        onClick={() => gridClick(4, 4)}
        bottomRight
      />
    </GridModalDiv>
  );
};
