import { BrowserRouter, Routes, Route } from "react-router-dom";

import View from "./views/View";

const Router = () => {
  const root = process.env.NODE_ENV === "development" ? "" : process.env.PUBLIC_URL;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={root + "/admin"} element={null} />
        <Route path={root + "/view"} element={<View />} />
        <Route path={root + "/print"} element={null} />
        <Route path={root + "/mpr"} element={null} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
