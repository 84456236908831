import React from "react";
import styled, { css } from "styled-components";
import useEventListener from "@utilityjs/use-event-listener";

import { AppContext } from "../../../context";
import { Tool, ImageWindow } from "../../../global";
import { TextCheckButton, SpacerDiv } from "./global";

enum Position {
  Top,
  Middle,
  Bottom,
}

const ContextMenuDiv = styled.div`
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  background-color: #016060F8;
  width: 200px;
  padding: 5px;
  border: 2px solid black;

  ${(props: {
    event: MouseEvent | null;
    height: number;
    position: Position;
  }) => {
    let top;

    switch (props.position) {
      case Position.Top:
        top = 0;
        break;
      case Position.Middle:
        top = props.height / 2;
        break;
      case Position.Bottom:
        top = props.height;
        break;
    }

    if (props.event !== null) {
      return css`
        transition: all 100ms ease-in;
        left: ${props.event.x}px;
        top: ${props.event.y - top}px;
        visibility: visible;
        opacity: 1;
      `;
    } else {
      return css`
        visibility: hidden;
        opacity: 0;
      `;
    }
  }}}
`;

const setWindow = (src: ImageWindow): void => {
  const event = new CustomEvent("fixedWindow", {
    detail: {
      center: src.center,
      width: src.width,
    },
  });
  window.dispatchEvent(event);
};

const fixedZoom = (zoom: number): void => {
  const event = new CustomEvent("fixedZoom", {
    detail: {
      zoom,
    },
  });
  window.dispatchEvent(event);
};

const dispatchCustomEvent = (name: string) => {
  const event = new CustomEvent(name);
  window.dispatchEvent(event);
};

const WindowTool = () => {
  const app = React.useContext(AppContext);

  return (
    <React.Fragment>
      <SpacerDiv />
      <TextCheckButton
        label={"Default Window"}
        shortcut={app.shortcuts.defaultWindow.label()}
        onMouseDown={() => dispatchCustomEvent("setDefaultWindow")}
      />
      <TextCheckButton
        label={"Full Dynamic"}
        shortcut={app.shortcuts.fullDynamic.label()}
        onMouseDown={() => dispatchCustomEvent("setFullDynamic")}
      />
      <SpacerDiv />
      <TextCheckButton
        label={app.fixedWindows.first.label}
        shortcut={app.shortcuts.firstWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.first)}
      />
      <TextCheckButton
        label={app.fixedWindows.second.label}
        shortcut={app.shortcuts.secondWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.second)}
      />
      <TextCheckButton
        label={app.fixedWindows.third.label}
        shortcut={app.shortcuts.thirdWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.third)}
      />
      <TextCheckButton
        label={app.fixedWindows.fourth.label}
        shortcut={app.shortcuts.fourthWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.fourth)}
      />
      <TextCheckButton
        label={app.fixedWindows.fifth.label}
        shortcut={app.shortcuts.fifthWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.fifth)}
      />
      <TextCheckButton
        label={app.fixedWindows.sixth.label}
        shortcut={app.shortcuts.sixthWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.sixth)}
      />
      <TextCheckButton
        label={app.fixedWindows.seventh.label}
        shortcut={app.shortcuts.seventhWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.seventh)}
      />
      <TextCheckButton
        label={app.fixedWindows.eighth.label}
        shortcut={app.shortcuts.eighthWindow.label()}
        onMouseDown={() => setWindow(app.fixedWindows.eighth)}
      />
      <SpacerDiv />
      <TextCheckButton
        label={"Negative"}
        shortcut={app.shortcuts.negative.label()}
        onMouseDown={() => dispatchCustomEvent("invert")}
      />
    </React.Fragment>
  );
};

const ZoomTool = () => {
  const app = React.useContext(AppContext);

  return (
    <React.Fragment>
      <SpacerDiv />
      <TextCheckButton
        label={app.fixedZooms.first.label}
        shortcut={app.shortcuts.firstZoom.label()}
        onMouseDown={() => fixedZoom(app.fixedZooms.first.zoom)}
      />
      <TextCheckButton
        label={app.fixedZooms.second.label}
        shortcut={app.shortcuts.secondZoom.label()}
        onMouseDown={() => fixedZoom(app.fixedZooms.second.zoom)}
      />
      <TextCheckButton
        label={app.fixedZooms.third.label}
        shortcut={app.shortcuts.thirdZoom.label()}
        onMouseDown={() => fixedZoom(app.fixedZooms.third.zoom)}
      />
      <TextCheckButton
        label={app.fixedZooms.fourth.label}
        shortcut={app.shortcuts.fourthZoom.label()}
        onMouseDown={() => fixedZoom(app.fixedZooms.fourth.zoom)}
      />
    </React.Fragment>
  );
};

const Main = ({
  event,
  children,
}: {
  event: MouseEvent | null;
  children?: React.ReactNode;
}) => {
  const app = React.useContext(AppContext);
  const menuHeight = document.getElementById("contextMenu")?.clientHeight;
  const delta = ((event?.y || 0) * 100) / window.innerHeight;

  let position;

  if (delta < 40) {
    position = Position.Top;
  } else if (delta < 60) {
    position = Position.Middle;
  } else {
    position = Position.Bottom;
  }

  /* Keyboard Shortcuts */
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (!app.blockShortcuts) {
        if (app.shortcuts.firstWindow.test(event)) {
          setWindow(app.fixedWindows.first);
        }

        if (app.shortcuts.secondWindow.test(event)) {
          setWindow(app.fixedWindows.second);
        }

        if (app.shortcuts.thirdWindow.test(event)) {
          setWindow(app.fixedWindows.third);
        }

        if (app.shortcuts.fourthWindow.test(event)) {
          setWindow(app.fixedWindows.fourth);
        }

        if (app.shortcuts.fifthWindow.test(event)) {
          setWindow(app.fixedWindows.fifth);
        }

        if (app.shortcuts.sixthWindow.test(event)) {
          setWindow(app.fixedWindows.sixth);
        }

        if (app.shortcuts.seventhWindow.test(event)) {
          setWindow(app.fixedWindows.seventh);
        }

        if (app.shortcuts.eighthWindow.test(event)) {
          setWindow(app.fixedWindows.eighth);
        }

        if (app.shortcuts.defaultWindow.test(event)) {
          dispatchCustomEvent("setDefaultWindow");
        }

        if (app.shortcuts.fullDynamic.test(event)) {
          dispatchCustomEvent("setFullDynamic");
        }

        if (app.shortcuts.fillViewport.test(event)) {
          dispatchCustomEvent("fillViewport");
        }

        if (app.shortcuts.reset.test(event)) {
          dispatchCustomEvent("reset");
        }

        if (app.shortcuts.negative.test(event)) {
          dispatchCustomEvent("invert");
        }

        if (app.shortcuts.firstZoom.test(event)) {
          fixedZoom(app.fixedZooms.first.zoom);
        }

        if (app.shortcuts.secondZoom.test(event)) {
          fixedZoom(app.fixedZooms.second.zoom);
        }

        if (app.shortcuts.thirdZoom.test(event)) {
          fixedZoom(app.fixedZooms.third.zoom);
        }

        if (app.shortcuts.fourthZoom.test(event)) {
          fixedZoom(app.fixedZooms.fourth.zoom);
        }

        if (app.shortcuts.bookmark.test(event)) {
          dispatchCustomEvent("bookmark");
        }

        if (app.shortcuts.save.test(event)) {
          dispatchCustomEvent("save");
        }
      }
    },
    [app]
  );

  useEventListener({
    target: window,
    eventType: "keydown",
    handler: handleKeyDown,
  });

  return (
    <ContextMenuDiv
      event={event}
      height={menuHeight || 0}
      position={position}
      id="contextMenu"
    >
      <TextCheckButton
        label={"Fill Viewport"}
        shortcut={app.shortcuts.fillViewport.label()}
        onMouseDown={() => dispatchCustomEvent("fillViewport")}
      />
      <TextCheckButton
        label={"Reset"}
        shortcut={app.shortcuts.reset.label()}
        onMouseDown={() => dispatchCustomEvent("reset")}
      />
      {children}
    </ContextMenuDiv>
  );
};

export const ContextMenu = () => {
  const app = React.useContext(AppContext);

  const [event, setEvent] = React.useState<MouseEvent | null>(null);

  const handleContextMenu = React.useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setEvent((current) => {
        if (current === null) {
          return event;
        } else {
          return null;
        }
      });
    },
    [setEvent]
  );

  const handleMouseDown = React.useCallback(() => {
    if (event) setEvent(null);
  }, [event]);

  useEventListener({
    target: window,
    eventType: "contextmenu",
    handler: handleContextMenu,
  });

  useEventListener({
    target: window,
    eventType: "mousedown",
    handler: handleMouseDown,
  });

  return (
    <React.Fragment>
      {(app.toolMode === Tool.Window && (
        <Main event={event} children={<WindowTool />} />
      )) ||
        (app.toolMode === Tool.Zoom && (
          <Main event={event} children={<ZoomTool />} />
        )) || <Main event={event} />}
    </React.Fragment>
  );
};
