import React from "react";
import axios from "axios";

import Modal from "../global/modal";
import Input from "../global/input";

import { RiLockPasswordFill } from "react-icons/ri";

import { AppContext, WasmContext } from "../../context";
import { writeAuthorization } from "../../global";

const AuthModal = () => {
  const inputRef = React.useRef(null);

  const app = React.useContext(AppContext);
  const wasm = React.useContext(WasmContext);

  const [show, setShow] = React.useState(false);
  const [bookmarksState, setBookmarksState] = React.useState(0);

  const ok = React.useCallback((): void => {
    setShow(false);
    if (app.setBlockShortcuts) app.setBlockShortcuts(false);

    if (inputRef.current) {
      axios
        .post("/api/users/" + app.user.id + "/login", {
          password: inputRef.current["value"],
        })
        .then((result) => {
          if (app.setToken) {
            const userId = app.user.id || "";
            const token = result.data.token || "";

            app.setToken(token);
            writeAuthorization(userId, token);

            if (bookmarksState === 2) setBookmarksState(0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, setShow, inputRef]);

  const cancel = React.useCallback((): void => {
    setShow(false);
    if (app.setBlockShortcuts) app.setBlockShortcuts(false);
  }, [setShow, app]);

  // Only updates for first time bookmarks.
  React.useEffect(() => {
    if (
      !app.standalone &&
      typeof app.studies[0] !== "undefined" &&
      app.permissions.bookmarks.read
    ) {
      if (wasm.global && bookmarksState === 0) {
        wasm.global._set_back_token(app.token);
        wasm.global._load_bookmarks(app.studies[0].studyIuid).then((result) => {
          if (result === "invalid" && app.setToken) {
            setBookmarksState(2);

            app.setToken(app.token);
            if (app.setBlockShortcuts) app.setBlockShortcuts(true);

            setShow(true);
          } else {
            setBookmarksState(1);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  return (
    <Modal
      title={"Login"}
      buttons={[
        { text: "OK", onClick: ok },
        {
          text: "Cancel",
          onClick: cancel,
        },
      ]}
      show={show}
      centerButtons
    >
      <Input
        inputRef={inputRef}
        placeholder={"Password"}
        icon={<RiLockPasswordFill size={24} />}
        password
      />
    </Modal>
  );
};

export default AuthModal;
