import React from "react";
import styled, { css } from "styled-components";

const ButtonDiv = styled.div`
  color: white;
  background-color: #016060;
  text-align: center;
  border-radius: 5px;

  :hover {
    opacity: 0.85;
  }

  :active {
    opacity: 0.75;
  }

  ${({ width, height }: { width?: number; height?: number }) => {
    const widthVal = width ? width : 100;
    const heightVal = height ? height : 32;

    return css`
      width: ${widthVal}px;
      height: ${heightVal}px;
      line-height: ${heightVal}px;
    `;
  }}
`;

const ButtonSpan = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
`;

interface ButtonProps {
  text: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const Button = (props: ButtonProps) => {
  return (
    <ButtonDiv
      width={props.width}
      height={props.height}
      onClick={props.onClick}
    >
      <ButtonSpan>{props.text}</ButtonSpan>
    </ButtonDiv>
  );
};

const RootDiv = styled.div`
  top: 50%;
  left: 50%;
  margin-top: -9em;
  background-color: #016060;
  border-radius: 3px;
  position: fixed;
  z-index: 5;
  box-shadow: 2px 2px 5px black;

  ${(props: { show: boolean; width?: number }) => {
    const widthValue = props.width ? props.width : 300;

    if (props.show) {
      return css`
        width: ${widthValue}px;
        margin-left: -${widthValue / 2}px;
        visibility: visible;
      `;
    } else {
      return css`
        width: ${widthValue}px;
        margin-left: -${widthValue / 2}px;
        visibility: hidden;
      `;
    }
  }}
`;

const TitleDiv = styled.div`
  padding: 10px;
  font-size: 18px;
  color: white;
  text-align: center;
`;

const BodyDiv = styled.div`
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #333;
`;

const ButtonsDiv = styled.div`
  display: flex;
  background: #333 !important;
  padding-top: 10px;
  padding-bottom: 20px;
  justify-content: space-around;
`;

const Modal = ({
  show,
  title,
  buttons,
  centerButtons,
  children,
}: {
  show: boolean;
  title: string;
  buttons: ButtonProps[];
  centerButtons?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <RootDiv show={show}>
      <TitleDiv>{title}</TitleDiv>
      <BodyDiv>{children}</BodyDiv>
      <ButtonsDiv>
        {buttons.map((props, index) => {
          return (
            <Button
              key={index}
              text={props.text}
              width={props.width}
              height={props.height}
              onClick={props.onClick}
            />
          );
        })}
      </ButtonsDiv>
    </RootDiv>
  );
};

export default Modal;
