import { Canvas, Global } from "./wasm";

export interface WasmGlobal {
  global: Global;
  _set_back_token: (token: string) => void;
  _load_bookmarks: (studyIuid: string) => Promise<string>;
  _insert_bookmarks: (studyIuid: string) => Promise<string>;
  _update_bookmarks: (studyIuid: string) => Promise<string>;
}

export class WasmGlobal {
  constructor(pacsToken: string) {
    this.global = Global._default(pacsToken);

    this._set_back_token = (token: string): void => {
      return this.global._set_back_token(token);
    };

    this._load_bookmarks = (studyIuid: string): Promise<string> => {
      return this.global._load_bookmarks(studyIuid);
    };

    this._insert_bookmarks = (studyIuid: string): Promise<string> => {
      return this.global._insert_bookmarks(studyIuid);
    };

    this._update_bookmarks = (studyIuid: string): Promise<string> => {
      return this.global._update_bookmarks(studyIuid);
    };
  }
}

export interface WasmCanvas {
  canvas: Canvas;
  init: (elementId: string) => void;
  reload: (elementId: string) => void;
  query: (src: Object[]) => Promise<void>;
  download: (pacsPath: string, parallel: boolean) => Promise<void>;
  setTool: (tool: Tool) => void;
  setAnnotations: (annotations: boolean) => void;
  setDicomOverlay: (dicomOverlay: boolean) => void;
  setMeasures: (measures: boolean) => void;
  setDefaultWindow: () => void;
  setFullDynamic: () => void;
  setWindow: (center: number, width: number) => void;
  fixedZoom: (zoom: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  invert: () => void;
  fillViewport: () => boolean;
  reset: () => void;
  nextImage: () => void;
  prevImage: () => void;
  playSpeedUp: () => void;
  playSpeedDown: () => void;
  playStop: () => void;
  keyDown: (key: string) => void;
  keyUp: (key: string) => void;
  bookmark: () => void;
}

export class WasmCanvas {
  constructor(wasmGlobal: WasmGlobal) {
    this.canvas = Canvas._default(wasmGlobal.global);

    this.init = (elementId: string): void => {
      this.canvas = Canvas._default(wasmGlobal.global);
      this.canvas._init(elementId);

      const canvas = this.canvas;

      const Resize = () => {
        const value = canvas._fill_viewport();

        if (value === false) {
          console.log("locked");
          setTimeout(Resize, 50);
        }
      };

      window.addEventListener("resize" + elementId, Resize);
    };

    this.reload = (elementId: string): void => {
      this.canvas._reload(elementId);
    };

    this.query = async (src: Object[]): Promise<void> => {
      const instances = JSON.stringify(src);
      return this.canvas._query(instances);
    };

    this.download = async (
      pacsPath: string,
      parallel: boolean
    ): Promise<void> => {
      return this.canvas._download(pacsPath, parallel);
    };

    this.setTool = (tool: Tool) => {
      return this.canvas._set_tool(tool);
    };

    this.setAnnotations = (annotations: boolean): void => {
      return this.canvas._set_annotations(annotations);
    };

    this.setDicomOverlay = (dicomOverlay: boolean): void => {
      return this.canvas._set_dicom_overlay(dicomOverlay);
    };

    this.setMeasures = (measures: boolean): void => {
      return this.canvas._set_measures(measures);
    };

    this.setDefaultWindow = (): void => {
      return this.canvas._set_default_window();
    };

    this.setFullDynamic = (): void => {
      return this.canvas._set_full_dynamic();
    };

    this.setWindow = (center: number, width: number): void => {
      return this.canvas._set_window(center, width);
    };

    this.fixedZoom = (zoom: number): void => {
      return this.canvas._fixed_zoom(zoom);
    };

    this.invert = (): void => {
      return this.canvas._invert();
    };

    this.fillViewport = (): boolean => {
      return this.canvas._fill_viewport();
    };

    this.reset = (): void => {
      return this.canvas._reset();
    };

    this.nextImage = (): void => {
      return this.canvas._next_image();
    };

    this.prevImage = (): void => {
      return this.canvas._prev_image();
    };

    this.playSpeedUp = (): void => {
      return this.canvas._play_speed_up();
    };

    this.playSpeedDown = (): void => {
      return this.canvas._play_speed_down();
    };

    this.playStop = (): void => {
      return this.canvas._play_stop();
    };

    this.keyDown = (key: string): void => {
      return this.canvas._key_down(key);
    };

    this.keyUp = (key: string): void => {
      return this.canvas._key_up(key);
    };

    this.bookmark = (): void => {
      return this.canvas._bookmark();
    };
  }
}

export interface WasmItem {
  index: number;
  name: string;
  iuids: Iuids;
  canvas: WasmCanvas;
}

/* Helpers */

/// Get query paramentes from URL.
export const getURLParameter = (sParam: string) => {
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split("&");
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
};

/// Attach to headers object a bearer token provided from URL token query parameter.
export const chainTokenHeader = (src: {}) => {
  const auth = getURLParameter("auth");
  if (auth) Object.assign(src, { Authorization: "Bearer " + auth });
  return src ? src : {};
};

export enum Tool {
  Browse,
  Window,
  Pan,
  Zoom,
  Rule,
  ROI,
  Angle,
  Trash,
}

export const defToolMode: Tool = Tool.Browse;

export class GridView {
  constructor(rows: number, cols: number) {
    this.rows = rows;
    this.cols = cols;
  }

  rows: number;
  cols: number;
}

export const defGridView: GridView = {
  rows: 1,
  cols: 1,
};

export interface Iuids {
  study: string;
  series: string;
  sop: string | null;
}

export interface Box {
  inverted: boolean;
}

export const defBoxes = (): Box[] => {
  let out: Box[] = [];
  for (let i = 0; i < 16; i++) {
    out.push({
      inverted: false,
    });
  }
  return out;
};

export class Dimensions {
  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  width: number;
  height: number;
}

/* Fixed Zoom */

export class Zoom {
  constructor(zoom: number) {
    this.zoom = zoom;
    this.label = zoom + "%";
  }

  zoom: number;
  label: string;
}

export interface FixedZooms {
  first: Zoom;
  second: Zoom;
  third: Zoom;
  fourth: Zoom;
}

export const defFixedZooms: FixedZooms = {
  first: new Zoom(100),
  second: new Zoom(200),
  third: new Zoom(400),
  fourth: new Zoom(800),
};

export function fixedZoomsFromUser(src: FixedZooms): FixedZooms {
  let out = defFixedZooms;
  type keyType = keyof typeof out;

  Object.keys(out).forEach((strkey) => {
    const key = strkey as keyType;
    if (typeof src[key] !== "undefined") {
      out[key] = new Zoom(src[key].zoom);
    }
  });

  return out;
}

/* Fixed Window */

export class ImageWindow {
  constructor(center: number, width: number) {
    this.center = center;
    this.width = width;
    this.label = "[" + center + "/" + width + "]";
  }

  center: number;
  width: number;
  label: string;
}

export interface FixedWidows {
  first: ImageWindow;
  second: ImageWindow;
  third: ImageWindow;
  fourth: ImageWindow;
  fifth: ImageWindow;
  sixth: ImageWindow;
  seventh: ImageWindow;
  eighth: ImageWindow;
}

export const defFixedWindows: FixedWidows = {
  first: new ImageWindow(20, 40),
  second: new ImageWindow(40, 80),
  third: new ImageWindow(80, 160),
  fourth: new ImageWindow(160, 320),
  fifth: new ImageWindow(320, 640),
  sixth: new ImageWindow(640, 1280),
  seventh: new ImageWindow(1280, 2560),
  eighth: new ImageWindow(2560, 5120),
};

export function fixedWindowsFromUser(src: FixedWidows): FixedWidows {
  let out = defFixedWindows;
  type keyType = keyof typeof out;

  Object.keys(out).forEach((strkey) => {
    const key = strkey as keyType;
    if (typeof src[key] !== "undefined") {
      out[key] = new ImageWindow(src[key].center, src[key].width);
    }
  });

  return out;
}

/* Fixed Grid */

export interface FixedGridViews {
  first: GridView;
  second: GridView;
  third: GridView;
  fourth: GridView;
  fifth: GridView;
  sixth: GridView;
  seventh: GridView;
  eighth: GridView;
}

export const defFixedGridViews: FixedGridViews = {
  first: new GridView(1, 1),
  second: new GridView(1, 2),
  third: new GridView(1, 3),
  fourth: new GridView(2, 2),
  fifth: new GridView(2, 3),
  sixth: new GridView(2, 4),
  seventh: new GridView(3, 3),
  eighth: new GridView(4, 4),
};

export function fixedGridViewsFromUser(src: FixedGridViews): FixedGridViews {
  let out = defFixedGridViews;
  type keyType = keyof typeof out;

  Object.keys(out).forEach((strkey) => {
    const key = strkey as keyType;
    if (typeof src[key] !== "undefined") {
      out[key] = new GridView(src[key].rows, src[key].cols);
    }
  });

  return out;
}

/* Keyboard Shortcuts */

export class Shortcut {
  constructor(char: string, altKey?: boolean, ctrlKey?: boolean) {
    this.char = char;
    this.altKey = altKey ? altKey : false;
    this.ctrlKey = ctrlKey ? ctrlKey : false;
  }

  char: string;
  altKey: boolean;
  ctrlKey: boolean;

  test(event: KeyboardEvent) {
    const flag =
      event.key.toUpperCase() === this.char &&
      event.altKey === this.altKey &&
      event.ctrlKey === this.ctrlKey;

    if (flag) event.preventDefault();
    return flag;
  }

  label() {
    return this.char;
  }
}

export interface Shortcuts {
  browseSeries: Shortcut;
  imageWindow: Shortcut;
  panImage: Shortcut;
  zoomImage: Shortcut;
  rule: Shortcut;
  roi: Shortcut;
  angle: Shortcut;
  trash: Shortcut;
  fullDynamic: Shortcut;
  firstWindow: Shortcut;
  secondWindow: Shortcut;
  thirdWindow: Shortcut;
  fourthWindow: Shortcut;
  fifthWindow: Shortcut;
  sixthWindow: Shortcut;
  seventhWindow: Shortcut;
  eighthWindow: Shortcut;
  defaultWindow: Shortcut;
  fillViewport: Shortcut;
  reset: Shortcut;
  negative: Shortcut;
  firstZoom: Shortcut;
  secondZoom: Shortcut;
  thirdZoom: Shortcut;
  fourthZoom: Shortcut;
  firstGridView: Shortcut;
  secondGridView: Shortcut;
  thirdGridView: Shortcut;
  fourthGridView: Shortcut;
  fifthGridView: Shortcut;
  sixthGridView: Shortcut;
  seventhGridView: Shortcut;
  eighthGridView: Shortcut;
  nextImage: Shortcut;
  prevImage: Shortcut;
  playStop: Shortcut;
  playSpeedUp: Shortcut;
  playSpeedDown: Shortcut;
  annotations: Shortcut;
  dicomOverlay: Shortcut;
  measures: Shortcut;
  bookmark: Shortcut;
  save: Shortcut;
}

export const defShortcuts: Shortcuts = {
  browseSeries: new Shortcut("Q"),
  imageWindow: new Shortcut("W"),
  panImage: new Shortcut("E"),
  zoomImage: new Shortcut("R"),
  rule: new Shortcut("T"),
  roi: new Shortcut("Y"),
  angle: new Shortcut("U"),
  trash: new Shortcut("I"),
  fullDynamic: new Shortcut("1"),
  firstWindow: new Shortcut("2"),
  secondWindow: new Shortcut("3"),
  thirdWindow: new Shortcut("4"),
  fourthWindow: new Shortcut("5"),
  fifthWindow: new Shortcut("6"),
  sixthWindow: new Shortcut("7"),
  seventhWindow: new Shortcut("8"),
  eighthWindow: new Shortcut("9"),
  defaultWindow: new Shortcut("0"),
  fillViewport: new Shortcut("Z"),
  reset: new Shortcut("X"),
  negative: new Shortcut("N"),
  firstZoom: new Shortcut("1", false, true),
  secondZoom: new Shortcut("2", false, true),
  thirdZoom: new Shortcut("3", false, true),
  fourthZoom: new Shortcut("4", false, true),
  firstGridView: new Shortcut("1", true),
  secondGridView: new Shortcut("2", true),
  thirdGridView: new Shortcut("3", true),
  fourthGridView: new Shortcut("4", true),
  fifthGridView: new Shortcut("5", true),
  sixthGridView: new Shortcut("6", true),
  seventhGridView: new Shortcut("7", true),
  eighthGridView: new Shortcut("8", true),
  nextImage: new Shortcut("ARROWDOWN"),
  prevImage: new Shortcut("ARROWUP"),
  playStop: new Shortcut(" "),
  playSpeedUp: new Shortcut("ARROWRIGHT"),
  playSpeedDown: new Shortcut("ARROWLEFT"),
  annotations: new Shortcut("D"),
  dicomOverlay: new Shortcut("F"),
  measures: new Shortcut("G"),
  bookmark: new Shortcut("B"),
  save: new Shortcut("S"),
};

export function shortcutsFromUser(src: Shortcuts): Shortcuts {
  let out = defShortcuts;
  type keyType = keyof typeof out;

  Object.keys(out).forEach((strkey) => {
    const key = strkey as keyType;
    if (typeof src[key] !== "undefined") {
      out[key] = new Shortcut(src[key].char, src[key].altKey, src[key].ctrlKey);
    }
  });

  return out;
}

/* User */

export interface User {
  id?: string;
  name: string;
  surname: string;
}

export const defUser = {
  id: undefined,
  name: "",
  surname: "",
};

/* Print */

export enum PrintSize {
  A3,
  A4,
}

export enum PrintLayout {
  Portrait,
  Landscape,
}

export interface Print {
  enabled: boolean;
  size: PrintSize;
  layout: PrintLayout;
}

export const defPrint = {
  enabled: false,
  size: PrintSize.A4,
  layout: PrintLayout.Portrait,
};

export function calcPrintSize(size: PrintSize, layout: PrintLayout) {
  let width, height;

  switch (size) {
    case PrintSize.A4:
      width = "210mm";
      height = "297mm";
      break;
    case PrintSize.A3:
      width = "297mm";
      height = "420mm";
      break;
  }

  switch (layout) {
    case PrintLayout.Portrait:
      return {
        width,
        height,
      };
    case PrintLayout.Landscape:
      return {
        width: height,
        height: width,
      };
  }
}

export interface RU {
  read: boolean;
  update: boolean;
}

export interface Permissions {
  bookmarks: RU;
  measures: RU;
}

export const defPermissions = {
  bookmarks: {
    read: true,
    update: false,
  },
  measures: {
    read: true,
    update: false,
  },
};

export function permissionsFromUser(src: Permissions): Permissions {
  let out = defPermissions;
  type keyType = keyof typeof out;

  Object.keys(out).forEach((strkey) => {
    const key = strkey as keyType;
    if (typeof src[key] !== "undefined") {
      out[key] = {
        read: src[key].read,
        update: src[key].update,
      };
    }
  });

  return out;
}

export const readToken = (userId: string): string => {
  return localStorage.getItem(userId) || "";
};

export const writeAuthorization = (userId: string, token: string) => {
  localStorage.setItem(userId, token);
};
